.hrMargin {
    margin:0;
}
.margin30 {
    margin-bottom: 15px;
}

.item-img-wrap {
    position: relative;
    text-align: center;
    overflow: hidden;
}

.item-img-wrap img {
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
    width: 100%;
}

/************************image hover effect*******************/
.item-img-wrap {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.item-img-wrap img {
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 100%;
  height: auto !important;
}

.item-img-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.item-img-overlay span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(http://bootstraplovers.com/templates/assan-2.2/main-template/img/plus.png) no-repeat center center rgba(0, 0, 0, 0.7);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -moz-transition: opacity 250ms linear;
  -o-transition: opacity 250ms linear;
  -webkit-transition: opacity 250ms linear;
  transition: opacity 250ms linear;
}

.item-img-wrap:hover .item-img-overlay span {
  opacity: 1;
}

.item-img-wrap:hover img {
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


/*************pagination***********/
.gallery-bottom .pagination {
  margin-top: 0px;
}

.pagination > li > a, .pagination > li > span {
  background-color: #ccc;
  padding: 3px 9px;
  color: #fff;
  border: 0px;
}

.pagination > li > a {
  margin-right: 5px;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #32c5d2;
}

.footerColour{
    background-color: rgba(0, 0, 0, 0.2);
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    float: right;

  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .headerBecground{
      background-color: #e9ecef;
  }
  .dropDownPadding{
    padding-right: 10px;
  }
  #dropdownMenuButton{
      background-color:transparent;
      color:#212529;
      border-color:transparent
  }
  .camTag{
    float: right;
    display: flex;
    
    margin-top: 10px;

}
.BasicexampleMargin{
  margin-top: 10px;
    margin-bottom: 10px;
}
#settingBar{
  width:165%;
}
.settingIconPointer{
  cursor: pointer;
}

.come-from-modal.left .modal-dialog,
.come-from-modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 350px;
    height: 100%;
    top: 112px;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
    
}
@media (max-width: 767.98px) {
  .come-from-modal.right .modal-dialog{
      width: 100% !important;
      height: 25px;
      margin: 0 !important;
      padding: 0 !important;
      top: 0px;
      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
    }
    .modelSizeFullScreencontent {
      height: auto !important;
      min-height: 100% !important;
      border-radius: 0 !important;
    }
  
}

.come-from-modal.left .modal-content,
.come-from-modal.right .modal-content {
    height: 82%;
    overflow-y: auto;
    border-radius: 0px;
}

.come-from-modal.left .modal-body,
.come-from-modal.right .modal-body {
    padding: 15px 15px 80px;
}
.come-from-modal.right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.come-from-modal.right.fade.in .modal-dialog {
    right: 0;
}
.rightModelTop{
  
}
#myModalLabel{
  /* Filtrer par */
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 18px;
line-height: 22px;
/* identical to box height */
letter-spacing: 0.00132353px;

color: #225684;

}
.hrtopandBottom{
  margin-top: 0rem !important;
    margin-bottom: 0rem !important;
}

/* .modal-backdrop {
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  
  backdrop-filter: blur(5px);
  background-color: #01223770;
} */

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: .5;
}
.modal-open{
  padding-right: 0px !important;
}
.setPadding{
  padding-right: 0px !important;
}

.checkbox label:after {
  content: '';
  display: table;
  clear: both;
}

.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: .25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: .5em;
}

.checkbox .cr .cr-icon {
  position: absolute;
  font-size: .8em;
  line-height: 0;
  top: 50%;
  left: 15%;
}

.checkbox label input[type="checkbox"] {
  display: none;
}

.checkbox label input[type="checkbox"]+.cr>.cr-icon {
  opacity: 0;
}

.checkbox label input[type="checkbox"]:checked+.cr>.cr-icon {
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled+.cr {
  opacity: .5;
}