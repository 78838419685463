.fullscreen {
  max-width: 100%;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #F2F2F2;
  z-index: 9999;
}
.fullscreen .tools {
  position: absolute;
  z-index: 99;
  left: 10px;
  top: 10px;
}
.fullscreen .transform-component-module_wrapper__1_Fgj {
  width: 100% !important;
  min-height: 100% !important;;
  height: 100vh !important;;
}
.fullscreen .transform-component-module_content__2jYgh img {
  margin: 0px;
  width: 100%;
}

.iconPadding{
  padding-left: 30px;
  float: right;
}
.buttonPadding{
  padding-left: 30px;
}

    .pb-cmnt-container {
        font-family: Lato;
        margin-top: 100px;
    }

    .pb-cmnt-textarea {
        resize: none;
        padding: 20px;
        height: 130px;
        width: 100%;
        border: 1px solid #F2F2F2;
    }
.shareButtonFloat{
  float: right
}
.commentBoxWidth{
  width: 100%;
}

.no-box-shadow {
  box-shadow: none
}

.no-box-shadow:focus {
  box-shadow: none
}

.day {
  font-size: 9px
}

.heart {
  border: 1px soild green !important;
  border-color: green !important;
  border-radius: 22px
}

.heart-icon {
  color: green
}

.comment-text {
  font-size: 12px
}

.delete {
  font-size: 13px;
  cursor: pointer
}
.popover.bs-tether-element-attached-top::after,
.popover.bs-tether-element-attached-top::before{
    left: 65%;
}
.popover {
  left: -119px !important;
}
.arrow{
  left: 248px !important;
}

.note-popup-dialog{
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
}