.app {
    margin: 0 auto;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    padding: 3rem 1rem 1rem;
  
    
  
    &__text {
      width: 50%;
     
    }
  
    &__collapse {
      width: 100%;
      display: block;
      position: relative;
      overflow: hidden;
      transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
      background-color: rgb(238, 237, 237);
     

      &--gradient {
        .app__content {
          border-color: transparent;
          
        }
  
        &:after {
          pointer-events: none;
          transition: opacity 300ms;
          will-change: opacity;
          opacity: 1;
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(transparent 50%, white);
         
        }
      }
  
      &--active {
        &:after {
          opacity: 0;
          
        }
      }
    }
  
    &__content {
      border: 1px solid transparent;
      padding: 1rem 0;
      margin-bottom: 3rem;
      position: relative;
      transition: 300ms;
      
    }
  
    &__toggle {
      width: 100%;
      
      border: 1px solid transparent;
      padding: 1rem;
      display: flex;
      justify-items: space-between;
      align-items: center;
      border-radius: 2px;
     
      background-color: rgb(238, 237, 237);
      transition: background-color 300ms;
      &--active {
        background-color: rgb(238, 237, 237);
        
      }
    }
  
    &__toggle-text {
      font-size: inherit;
      
     
    }
  
    &__button {
      display: block;
      width: 20rem;
      max-width: 100%;
      margin: 0 auto;
      padding: 1rem;
      
    }
  
    &__image {
      width: 100%;
      max-width: 100%;
      height: auto;
      display: block;
    }
    &__glyphicon_right{
        width: 100%;
        max-width: 100%;
        height: auto;
        display: block;
        margin-left: 0px;
    }
  }
  
  .icon {
      margin: 10px;
    width: 1em;
    height: 1em;
    transition: transform 200ms cubic-bezier(0, 1, 0, 1);
    will-change: transform;
    &--expanded {
      transform: rotateZ(90deg);
    }
  }
  
  .rotate90 {
    transform: rotateZ(0deg);
  }
  