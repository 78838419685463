.producteure-note-main-box{
    background-color: rgba(255, 255, 255, 0.8);
    margin: auto;
    max-width: 1200px;
    padding: 10px;
    
}


.producteure-note-hr{
    margin-left: auto;
    margin-right: auto;
}

.producteure-note-button2{
    float: right;
    width: 122px;
    height: 30px;
    color: #0178D4;
    background-color: #FFFFFF;
    border: 1px solid #0178D4;
    box-sizing: border-box;
    border-radius: 4px;
}

.producteure-note-dropdownnumber{
    color: #8F9094;  
}

.hrshadow{
    border:none;
  height: 20px;
	height: 50px;
	margin-top: 0;
	box-shadow: 0 13px 13px -13px #333;
    margin-left: auto;
    margin-right: auto;
}



@media only screen and (max-width: 768px){
  .producteure-note-sub-box{
    background-color: #F0F0F0;    
    margin: auto;
    max-width: 1100px;
   
   }

  .producteure-note-button{
    float: left;
    margin-top: 20px;
    width: 157px;
    height: 40px;
    border: 1px solid #0178D4;
    background-color: #0178D4;
    color: #FFFFFF;
    border-radius: 4px;
  }

  .producteure-note-button-aligment-mobile{
    margin-top:20px;
  }
}

@media only screen and (min-width: 768px){
  .producteure-note-sub-box{
    background-color: #F0F0F0;    
    margin: auto;
    max-width: 1100px;
    padding: 10px;
    
  }
  .producteure-note-button{
    float: right;
    margin-top: 20px;
    width: 157px;
    height: 40px;
    border: 1px solid #0178D4;
    background-color: #0178D4;
    color: #FFFFFF;
    border-radius: 4px;
  }

}

.producteure-note-icon-navigation{
    float: right;
    margin-bottom: 20;
}

.producteure-note-sub-box-2{
    background-color: #FFFFFF;    ;
    margin: auto;
    max-width: auto;
    padding: auto;

    
}

.producteure-note-button3{
    float: right;
    width: 122px;
    height: 30px;
    color: #0178D4;
    background-color: #FFFFFF;
    border: 1px solid #0178D4;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 10px;
}

.producteure-note-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* width: 321.33px;
    height: 241px; */
}



.producteure-note-pagination {
    display: inline-block;
    
  }
  
  .producteure-note-pagination a {
    color: black;
    float: left;
    padding: 8px 11px;
    text-decoration: none;
  }
  
  .producteure-note-pagination a.active {
   
    color:#FFFFFF;
    width:20px;
    height: 22px;
    margin-right:200px;
  }
  
  .producteure-note-pagination a:hover:not(.active) {background-color:#225684;color:#FFFFFF;}

  .producteure-note-mobile-btn {
    background-color: #225684;
    border: none;
    color: white;
    font-size: 14px;
    cursor: pointer;
    width: 55px;
    height: auto;
    
  }

  .producteure-note-mobile-arrow-color-btn{
    color: black;
  }

  .producteure-note-mobile-btn-aligment{
    margin: auto;
    width: 50%;
    padding: 10px
  }

  .producteure-note-bottom-button{
    /* position: absolute;
    left: 9.2%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */
    width: auto;
    height: 40px;
    border: 1px solid #0178D4;
    color: #0178D4;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .producteure-note-sidebar{
    background-color:  #FFFFFF;
    
  }

  .producteure-note-doted-button-aligment{
    margin-top: 0px;
    margin-left: 40px;
    cursor: pointer;
  }

  .notesidebar-firstcoloum-background-colour{
    background-color:  #F1F3F9;
    
  }

  .producture-note-topic-fonts{
    color: #202124;
    /* font-size: 16px; */
    font-weight: bold;
  }

  .producture-note-paragraph{
    color: #202124;
  }
  
  .producture-note-modal-button{
    width: 282px;
    height: 71px;
    color: #202124;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    /* border-radius: 4px;
    margin-top: 10px; */
  }
  
  .producture-note-mobile-filter-button{
    margin-right: 130px;
  }
  

  .producture-note-mobile-filter-Touteffacer-button{
    float: right;
    width: 122px;
    height: 40px;
    color: #0178D4;
    background-color: #FFFFFF;
    border: 1px solid #0178D4;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 60px;
  }

  .producture-note-mobile-filter-appliqure-button{
    width: 122px;
    height: 40px;
    margin-right: 40px;
  }

  .producture-note-mobile-Supprimerlapage-button{
    margin-left: 120px;
  }

  .producture-note-mobile-pagination{
    background-color: #225684;
    ;
  }

  .producture-note-mobile-pagination-arrow-ailgment{
    margin-left: 20px;
  }

  .producture-note-filter-mobile-color{
    color: black;
  }

  .producture-note-filterpar-mobile-color{
    color: #225684;
    ;
  }

  .chip, .singleChip {
    white-space: normal !important;
}