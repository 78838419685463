/* If any modal popup issues, please remove this below comment */
.modal-backdrop.fade {
  opacity: 0;
  display: none !important;
} 
.modal::after {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}
.modal-dialog {
  position: relative;
  width: 700px;
  z-index: 1044;
}
.app__collapse .parastyle {
  cursor: pointer;
}
.bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

/* producture note side bar*/


@media only screen and (max-width: 768px){
  .producture-note-margin{
    margin: 0px;
  }
}

@media only screen and (min-width: 768px){
  .producture-note-margin{
    margin: 20px;
  }
}
.producture-note-mini-icon-aligmment{
  margin-top: 10px;
  margin-left: -20px;
}

.producture-note-mobile-sidebar-font-color{
  color: black;
}

.producture-note-sidebar-arrow{
  margin-top: 5px;
}

.producture-note-mobile-sidebar-background-color{
  background-color: white;
}