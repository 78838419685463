@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal.show .modal-dialog{
 
  width: 700px;
  max-width: 90%;

}

.showimportant {
  display: block !important;
  outline: 0 !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.logintextaligmentone{
    margin-top: -20px;
    color: #202124;
    font-weight: 400;
    font-size: 16px;

}

.logintextaligmenttwo{
    margin-top: 10px;
}

.logintextaligmentthree{
    margin-top: 5px;
    /* color: #202124; */
}

.logintextaligmentfour{
    margin-top: 10px;
}

.loginbuttonaligment{
    margin-top: 20px;
    background-color:#0178D4;
    font-weight: 600;
    font-size: 14px;
}

.loginfontcolour{
    color: #202124;
    font-weight: 700;
}


.form-group.logo-upload-field img{
  max-height: 60px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  max-width: 80%;
  height:100% ;
  
  margin: 0 auto; /* Added */
        float: none; /* Added */
        margin-top:40px ;

}
@media only screen and (max-width: 768px) {
  div.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    max-width: 100%;
    height:100% ;
    
    margin: 0 auto; /* Added */
          float: none; /* Added */
          margin-top:40px ;
  }
}

@media only screen and (max-width: 1200px) {
  div.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    min-width: 90%;
    height:100% ;
    
    margin: 0 auto; /* Added */
          float: none; /* Added */
          margin-top:40px ;
  }
}

.settings_content{
background-color:#f0f0f0;
width:100%;
overflow-y: scroll;
}

.btn_position{
  position: absolute;
  right: 0;

}

.form-group-right{
  margin-right:6px;
  margin-top: 0px;
  
}
.form-group-left{
  margin-right:20px;
  
}

.column {
  float: left;
  width: 50%;
  padding: 10px;
  
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
  
}
.fragment{
  margin: 6px;
}

.primaryTop{
margin-top: 10px;
margin-right: 0px;
}

.headerTop{
  margin-top: 40px;
  margin-bottom: 20px;
  
  }
  .form-item-margin{
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .gray-lable-margin{
    margin-top: 20px;
    padding-left: 40px;
    padding-bottom: 40px;
  }
  .column_left_alert {
    float: left;
    width: 15%;
  }

  .column_right_alert {
    float: right;
    width: 85%;
    font-size: 12px;
    
  }

  .img-center {margin:0 auto;
    float:none;
    }

  .inner-form-item-margin{
    margin-top: 20px;
    
  }

  .inner-form-row-margin{
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
    
  }
  .li {
    width: 33.3%;
    float: left;
  }
  
  img {
    border: 0 none;
    display: inline-block;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
   
 
  }

  .img-set-right{
    margin-top: auto;

  }

.glyph-icon-posision{
  position: absolute;
  right: 10px;
  padding-right:1rem; 
  
  
  
}
  
.column-category {
  margin: 0;
  
} 

.category-inner-font{
  font-size: 25px;
}

.collaps_bg{
  background-color: darkkhaki;
}
  
.inner-form-row-margin-category{
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  background-color: white;
  
}

div.vspace1em {
  clear: both;
  height: 1em;
}

.pop-style{
  left: -16px !important;
  top: 210px !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.pop-style-cat{
  left: -16px !important;
  top: 178px !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.pop-dossiernotes{
 margin-top: 92px;
 margin-left: -25px;
}

.pop-main{
  background-color: white;
  margin-right: 70px;
}

.alert-margin{
  margin-top: 2rem;
}

.popover_style{
  justify-content: 'center';
  padding-left: 10px;
  padding-right: 10px;
 
  background-color: white;
  

}

.poppver_text_style{
  
  justify-content: 'center'
}

.column_left_tags_photos_drop_down {
  padding-left: 20px;
  float: left;
  width: 35%;
}

.column_right__tags_photos_drop_down {
  padding-right: 20px;
  padding-left: 10px;
  float: left;
  width: 65%;
  font-size: 12px;
  
}

.align-me {
  display: flex;
  align-items: center;
  
  
  
}

.center-element{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.center-two-buttons {
  float:none;
  margin:0 auto;

}


@media only screen and (max-width: 768px) {
  .mobile_button{
    width: 100%;
  }
  .alert-height-invitation{
    min-height: 65px;
  }
  .come-from-modal.right .modal-dialog{
    width: 100% !important;
    height: 25px;
    margin: 0 !important;
    padding: 0 !important;
    top: 0px;
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }
  .modelSizeFullScreencontent {
    height: auto !important;
    min-height: 100% !important;
    border-radius: 0 !important;
  }

  .button-align-mobile{
   margin-right: 39%;
  }

  
  
}



.right-actin-buttons {
  float:none;
  margin:0 auto;
  vertical-align: middle;
}

.alert_icon{
  display: flex;
  justify-content: flex-end;
}


@media only screen and (max-width: 768px) {
  /* Force table to not be like tables anymore */
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
  display: block;
  }
   
  /* Hide table headers (but not display: none;, for accessibility) */
  #no-more-tables thead tr {
  position: absolute;
  top: -9999px;
  left: -9999px;
  }
   
  #no-more-tables tr { border: 1px solid #ccc; }
    
  #no-more-tables td {
  /* Behave like a "row" */
  border: none;
  border-bottom: 1px solid #ccc;
  position: relative;
  padding-left: 50%;
  white-space: normal;
  text-align:left;
  }
   
  #no-more-tables td:before {
  /* Now like a table header */
  position: absolute;
  /* Top/left values mimic padding */
  top: 6px;
  left: 6px;
  width: 45%;
  padding-right: 10px;
  white-space: nowrap;
  text-align:left;
  font-weight: bold;
  }
   
  /*
  Label the data
  */
  #no-more-tables td:before { content: attr(data-title); }

  
  }


  @media(min-width:768px){
    .onlyForMobile{
        display: none !important;
    }
  }
  @media(max-width:768px){
    .onlyForDesktop{
        display: none !important;
    }
  }


  .switch {
    position: absolute;
    display: inline-block;
    width: 30px;
    height: 17px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }



  .rotate {
    -webkit-animation: rotate-keyframes 0s;
            animation: rotate-keyframes 0s;
   }
   
   @-webkit-keyframes rotate-keyframes {
    from {
     -webkit-transform: rotate(0deg);
             transform: rotate(0deg);
    }
   
    to {
     -webkit-transform: rotate(90deg);
             transform: rotate(90deg);
    }
   }
   
   @keyframes rotate-keyframes {
    from {
     -webkit-transform: rotate(0deg);
             transform: rotate(0deg);
    }
   
    to {
     -webkit-transform: rotate(90deg);
             transform: rotate(90deg);
    }
   }

   .rotate_back {
    -webkit-animation: rotate-keyframes2 0s;
            animation: rotate-keyframes2 0s;
   }
   
   @-webkit-keyframes rotate-keyframes2 {
    from {
     -webkit-transform: rotate(90deg);
             transform: rotate(90deg);
    }
   
    to {
     -webkit-transform: rotate(0deg);
             transform: rotate(0deg);
    }
   }
   
   @keyframes rotate-keyframes2 {
    from {
     -webkit-transform: rotate(90deg);
             transform: rotate(90deg);
    }
   
    to {
     -webkit-transform: rotate(0deg);
             transform: rotate(0deg);
    }
   }
  
   @media only screen and (min-width: 768px) {
    .arrow_collapse_margin {
     
      margin-right: 40px; 
           
    }
  }
  @media only screen and (max-width: 768px) {
    .arrow_collapse_margin {
     
      margin-right: 20px; 
           
    }

    .bloxaligment {
      margin-top: -17px;
    }
  }
  
  .badgeContainer{
    flex:1 1;
    flex-direction: 'row';
    flex-wrap: 'wrap';
  }



/* ------------------------------------------------- */

.side-bar-box-width{

  width:auto;
  height: 132px;
  float: left;
  

 
}

.mobileboxclass{
  width: 600px;
  height: 50px;
  background-color: white;
}

.sidebarbackgroundcolour{
  background-color: white;
}

/* 
----------------------------------------------------------------------------------- */

.small-box-mrgin{
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  background-color: white;
}

.full-width-btn {
  width: 100%;
  border: 0px !important;
  padding: 10px 0px !important;
}

.navbarbuttonstyle{
  padding: 0px 125px;
  background-color: white;
  text-align: left;
}

.nopadding{
  padding: 0px !important;
}

.setting-information-general-jpg-box{
  background-color:#F1F3F9;
  height: auto;
  width: 300px;
}

.setting-information-general-bottom-text{
  padding-left:110px;
}

.setting-plandeferme-box{
  margin-top: 65px;
}

.setting-siteandcategory{
  margin-top: 65px;
}

.setting-conection-sending-box{
  display: inline-block;
  margin-top: -80px;
  width: 100%;
}

.boxalignleft{
  position: absolute;
  right: 405px;
}

.admin-upload-align-without-width {
 
  margin-right: 10px;
  margin-left: 10px;
  padding-top: 20px;
 
}

.admin-upload-align {

  max-width: 416px; 
  margin-right: 10px;
  margin-left: 10px;
  padding-top: 20px;
 
}

.admin-upload-long {

  max-width: 600px; 
  margin-right: 10px;
  margin-left: 10px;
  padding-top: 20px;
 
}

.admin-dashboard-grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 26px;
  text-align: center;
  max-width: 416px; 
  height: 220px;
  margin: 17px auto;
  color:#225684;
}

@media only screen and (max-width: 500px){
  .admin-dashboard-grid-item{
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    text-align: center;
    max-width: 416px; 
    height: 220px;
    margin: 17px auto;
    color:#225684;
  }
}

.admin-dashboard-box {
  margin: auto;
  max-width: 849px;
  /* border: 3px solid #73AD21; */
  padding: 10px;
}

/* admin settings category */
.admin-column-quater {
  float: left;
  width: 25%;
  padding: 10px;
}

.admin-padding {
  padding: 10px;
}

.admin-column-white{
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
  background-color: white;
  
}

.des-top {
  margin-top: 100px;
}

.update-fonts {
  font-size: 13px !important;
}

.input-height {
  height: 45px !important;
}

.btn-right-pad{
  padding-right: 10px !important;
}

.table-width{
  width: 100%;
}
.table-width tr {
  width: 100%;
}

.admin-enterprise{
  margin-left: 100px;
}

@media only screen and (max-width: 768px){
  .margintopscreen{
      padding-top: 100px;
  }
}

.react-tel-input .form-control {

  width: 100% !important;
}

.react-tel-input .selected-flag .arrow{

  margin-left: 104px !important;
}

.react-tel-input .country-list {
  width: 377px !important;
}

.react-tel-input .selected-flag .arrow {
  display: none;
  }


.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  max-width: 80%;
  height:100% ;
  
  margin: 0 auto; /* Added */
        float: none; /* Added */
        margin-top:40px ;

}
@media only screen and (max-width: 768px) {
  div.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    max-width: 100%;
    height:100% ;
    
    margin: 0 auto; /* Added */
          float: none; /* Added */
          margin-top:40px ;
  }
}

@media only screen and (max-width: 1200px) {
  div.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    min-width: 90%;
    height:100% ;
    
    margin: 0 auto; /* Added */
          float: none; /* Added */
          margin-top:40px ;
  }
}

.settings_content{
background-color:#f0f0f0;
width:100%;
overflow-y: scroll;
}

.btn_position{
  position: absolute;
  right: 0;

}

.form-group-right{
  margin-right:6px;
  margin-top: 0px;
  
}
.form-group-left{
  margin-right:20px;
  
}

.column {
  float: left;
  width: 50%;
  padding: 10px;
  
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
  
}
.fragment{
  margin: 6px;
}

.primaryTop{
margin-top: 10px;
margin-right: 0px;
}

.headerTop{
  margin-top: 40px;
  margin-bottom: 20px;
  
  }
  .form-item-margin{
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .gray-lable-margin{
    margin-top: 20px;
    padding-left: 40px;
    padding-bottom: 40px;
  }
  .column_left_alert {
    float: left;
    width: 15%;
  }

  .column_right_alert {
    float: right;
    width: 85%;
    font-size: 12px;
    
  }

  .img-center {margin:0 auto;
    float:none;
    }

  .inner-form-item-margin{
    margin-top: 20px;
    
  }

  .inner-form-row-margin{
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
    
  }
  .li {
    width: 33.3%;
    float: left;
  }
  
  img {
    border: 0 none;
    display: inline-block;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
   
 
  }

  .img-set-right{
    margin-top: auto;

  }

.glyph-icon-posision{
  position: absolute;
  right: 10px;
  padding-right:1rem; 
  
  
  
}
  
.column-category {
  margin: 0;
  
} 

.category-inner-font{
  font-size: 25px;
}

.collaps_bg{
  background-color: darkkhaki;
}
  
.inner-form-row-margin-category{
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  background-color: white;
  
}

div.vspace1em {
  clear: both;
  height: 1em;
}

.pop-style{
  left: -16px !important;
  top: 210px !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}
.pop-style-cat{
  left: -16px !important;
  top: 178px !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.pop-main{
  background-color: white;
  margin-right: 70px;
}

.alert-margin{
  margin-top: 2rem;
}

.popover_style{
  justify-content: 'center';
  padding-left: 10px;
  padding-right: 10px;
 
  background-color: white;
  

}

.poppver_text_style{
  
  justify-content: 'center'
}

.column_left_tags_photos_drop_down {
  padding-left: 20px;
  float: left;
  width: 35%;
}

.column_right__tags_photos_drop_down {
  padding-right: 20px;
  padding-left: 10px;
  float: left;
  width: 65%;
  font-size: 12px;
  
}

.align-me {
  display: flex;
  align-items: center;
  
  
  
}

.center-element{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.center-two-buttons {
  float:none;
  margin:0 auto;

}


@media only screen and (max-width: 768px) {
  .mobile_button{
    width: 100%;
  }
  .alert-height-invitation{
    min-height: 65px;
  }
  .come-from-modal.right .modal-dialog{
    width: 100% !important;
    height: 25px;
    margin: 0 !important;
    padding: 0 !important;
    top: 0px;
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }
  .modelSizeFullScreencontent {
    height: auto !important;
    min-height: 100% !important;
    border-radius: 0 !important;
  }

  .button-align-mobile{
   margin-right: 39%;
  }

  
  
}



.right-actin-buttons {
  float:none;
  margin:0 auto;
  vertical-align: middle;
}

.alert_icon{
  display: flex;
  justify-content: flex-end;
}


@media only screen and (max-width: 768px) {
  /* Force table to not be like tables anymore */
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
  display: block;
  }
   
  /* Hide table headers (but not display: none;, for accessibility) */
  #no-more-tables thead tr {
  position: absolute;
  top: -9999px;
  left: -9999px;
  }
   
  #no-more-tables tr { border: 0px solid #ccc; }
    
  #no-more-tables td {
  /* Behave like a "row" */
  border: none;
  border-bottom: 0px solid #ccc;
  position: relative;
  padding-left: 50%;
  white-space: normal;
  text-align:left;
  }
   
  #no-more-tables td:before {
  /* Now like a table header */
  position: absolute;
  /* Top/left values mimic padding */
  top: 6px;
  left: 6px;
  width: 45%;
  padding-right: 10px;
  white-space: nowrap;
  text-align:left;
  font-weight: bold;
  }
   
  /*
  Label the data
  */
  #no-more-tables td:before { content: attr(data-title); }

  
  }


  @media(min-width:768px){
    /* #modelExample{
        display: none !important;
    } */

    /* .modal-backdrop{
        display: none !important;
    } */
  }


  .switch {
    position: absolute;
    display: inline-block;
    width: 30px;
    height: 17px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }



  .rotate {
    -webkit-animation: rotate-keyframes 0s;
            animation: rotate-keyframes 0s;
   }
   
   @-webkit-keyframes rotate-keyframes {
    from {
     -webkit-transform: rotate(0deg);
             transform: rotate(0deg);
    }
   
    to {
     -webkit-transform: rotate(90deg);
             transform: rotate(90deg);
    }
   }
   
   @keyframes rotate-keyframes {
    from {
     -webkit-transform: rotate(0deg);
             transform: rotate(0deg);
    }
   
    to {
     -webkit-transform: rotate(90deg);
             transform: rotate(90deg);
    }
   }

   .rotate_back {
    -webkit-animation: rotate-keyframes2 0s;
            animation: rotate-keyframes2 0s;
   }
   
   @-webkit-keyframes rotate-keyframes2 {
    from {
     -webkit-transform: rotate(90deg);
             transform: rotate(90deg);
    }
   
    to {
     -webkit-transform: rotate(0deg);
             transform: rotate(0deg);
    }
   }
   
   @keyframes rotate-keyframes2 {
    from {
     -webkit-transform: rotate(90deg);
             transform: rotate(90deg);
    }
   
    to {
     -webkit-transform: rotate(0deg);
             transform: rotate(0deg);
    }
   }
  
   @media only screen and (min-width: 768px) {
    .arrow_collapse_margin {
     
      margin-right: 40px; 
           
    }

    .admin-entreprise-actions-view-size{
      margin-left: 40px;
      margin-right: 20px;
      padding-bottom: 20px;
    }

  }
  @media only screen and (max-width: 768px) {
    .arrow_collapse_margin {
     
      margin-right: 20px; 
           
    }

    .admin-entreprise-actions-button-parent{
      height: auto;
      overflow: hidden;
   
    }

    .admin-entreprise-actions-button-child-one{
      padding-left: 70px;
    }

    .admin-entreprise-actions-button-child-two{
      float: right;
    }

    .admin-popup-max-screen{
      max-width: 100%;
    }
    
  }
  
  @media only screen and (max-width : 320px) {

    .admin-entreprise-actions-button-child{
      padding-left: 165px !important;
    }
    .admin-entreprise-actions-button-parent{
      height: auto !important;
      overflow: hidden !important;
      margin-left: 70px !important;
      margin-right: 0px !important;
    }

  }
  
  .badgeContainer{
    flex:1 1;
    flex-direction: 'row';
    flex-wrap: 'wrap';
  }



/* ------------------------------------------------- */

.side-bar-box-width{

  width:auto;
  height: 132px;
  float: left;
  

 
}

.mobileboxclass{
  width: 600px;
  height: 50px;
  background-color: white;
}

.sidebarbackgroundcolour{
  background-color: white;
}

/* 
----------------------------------------------------------------------------------- */


.small-box-mrgin{
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  background-color: white;
}


.full-width-btn {
  width: 100%;
  border: 0px !important;
  padding: 10px 0px !important;
}
.navbarbuttonstyle{
  padding: 0px 10px;
  background-color: white;
  text-align: left;
}



.nopadding{
  padding: 0px !important;
}


.setting-information-general-jpg-box{
  background-color:#F1F3F9;
  height: auto;
  width: 300px;

}

.setting-information-general-bottom-text{
  padding-left:110px;
}

.setting-plandeferme-box{
  margin-top: 65px;
}

.setting-siteandcategory{
  margin-top: 65px;
}

.setting-conection-sending-box{
  display: inline-block;
  margin-top: -80px;
  width: 100%;
}
.textcolour {
    color: #FFFFFF;
    font-size: 16px;
}

.legaltextcolour {
    color: #87AAC8;
    font-size: 16px;
}

.footerbackground {
    background: #225684;

}

.linecolour {
    height: 3px;
    border-width: 0;
    color: 2px solid #FFFFFF23;
    background-color: 1px solid #FFFFFF23;

}

.line {
    height: 1px;
    border-width: 0;
    color: #87AAC8;
    background-color: #87AAC8;
}


.link-text {
    color: #87AAC8 !important;
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    div.centerword {
        text-align: center;
    }
}

.hrMargin {
    margin:0;
}
.margin30 {
    margin-bottom: 15px;
}

.item-img-wrap {
    position: relative;
    text-align: center;
    overflow: hidden;
}

.item-img-wrap img {
    transition: all 200ms linear;
    width: 100%;
}

/************************image hover effect*******************/
.item-img-wrap {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.item-img-wrap img {
  transition: all 200ms linear;
  width: 100%;
  height: auto !important;
}

.item-img-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.item-img-overlay span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(http://bootstraplovers.com/templates/assan-2.2/main-template/img/plus.png) no-repeat center center rgba(0, 0, 0, 0.7);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  transition: opacity 250ms linear;
}

.item-img-wrap:hover .item-img-overlay span {
  opacity: 1;
}

.item-img-wrap:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


/*************pagination***********/
.gallery-bottom .pagination {
  margin-top: 0px;
}

.pagination > li > a, .pagination > li > span {
  background-color: #ccc;
  padding: 3px 9px;
  color: #fff;
  border: 0px;
}

.pagination > li > a {
  margin-right: 5px;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #32c5d2;
}

.footerColour{
    background-color: rgba(0, 0, 0, 0.2);
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    float: right;

  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .headerBecground{
      background-color: #e9ecef;
  }
  .dropDownPadding{
    padding-right: 10px;
  }
  #dropdownMenuButton{
      background-color:transparent;
      color:#212529;
      border-color:transparent
  }
  .camTag{
    float: right;
    display: flex;
    
    margin-top: 10px;

}
.BasicexampleMargin{
  margin-top: 10px;
    margin-bottom: 10px;
}
#settingBar{
  width:165%;
}
.settingIconPointer{
  cursor: pointer;
}

.come-from-modal.left .modal-dialog,
.come-from-modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 350px;
    height: 100%;
    top: 112px;
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
    
}
@media (max-width: 767.98px) {
  .come-from-modal.right .modal-dialog{
      width: 100% !important;
      height: 25px;
      margin: 0 !important;
      padding: 0 !important;
      top: 0px;
      -webkit-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
    }
    .modelSizeFullScreencontent {
      height: auto !important;
      min-height: 100% !important;
      border-radius: 0 !important;
    }
  
}

.come-from-modal.left .modal-content,
.come-from-modal.right .modal-content {
    height: 82%;
    overflow-y: auto;
    border-radius: 0px;
}

.come-from-modal.left .modal-body,
.come-from-modal.right .modal-body {
    padding: 15px 15px 80px;
}
.come-from-modal.right.fade .modal-dialog {
    right: -320px;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.come-from-modal.right.fade.in .modal-dialog {
    right: 0;
}
.rightModelTop{
  
}
#myModalLabel{
  /* Filtrer par */
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 18px;
line-height: 22px;
/* identical to box height */
letter-spacing: 0.00132353px;

color: #225684;

}
.hrtopandBottom{
  margin-top: 0rem !important;
    margin-bottom: 0rem !important;
}

/* .modal-backdrop {
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  
  backdrop-filter: blur(5px);
  background-color: #01223770;
} */

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: .5;
}
.modal-open{
  padding-right: 0px !important;
}
.setPadding{
  padding-right: 0px !important;
}

.checkbox label:after {
  content: '';
  display: table;
  clear: both;
}

.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: .25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: .5em;
}

.checkbox .cr .cr-icon {
  position: absolute;
  font-size: .8em;
  line-height: 0;
  top: 50%;
  left: 15%;
}

.checkbox label input[type="checkbox"] {
  display: none;
}

.checkbox label input[type="checkbox"]+.cr>.cr-icon {
  opacity: 0;
}

.checkbox label input[type="checkbox"]:checked+.cr>.cr-icon {
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled+.cr {
  opacity: .5;
}

.uploadFieldFix {
  max-height: auto; margin-left: 10px; margin-right: 10px; padding-top: 20px;
}

.uploadFieldsTitle {
  display: block;
  margin-left: 9px;
  margin-bottom: 15px;
}
/* Loader */
.pageLoaderIncluded {
    position: relative;
    min-height: 600px;
}
.footerbackground {
    position: relative;
}
.page_loader_wrapper {
    z-index: 99999;
    opacity: 0.7;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: #080915;
    width: 100%;
    display: flex;
    min-height: 600px;
    /* height: 100vh; */
    align-items: center;
    justify-content: center;
  }
  section.page_loader {
    width: 100%;
    display: block;
    text-align: center;
    min-height: 215px;
    margin: 1%;
  }
  @media only screen and (max-width: 600px) {
    section.page_loader {
      min-width: 350px;
    }
  }
     
  .loader {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 75px;
    display: inline-block;
    vertical-align: middle;
  }
  
  .loader_in_wrap {
    position: relative;
    width: 120px;
    height: 120px;
    display: inline-block;
    vertical-align: middle;
  }
  .loaderLogoImage {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    min-height: 600px;
    /* height: 100vh; */
    align-items: center;
    justify-content: center;
  }
  
  .loader-star {
    position: absolute;
    top: calc(50% - 12px);
  }
  
    /*LOADER-1*/
    
    .loader-1 .loader-outter {
        position: absolute;
        border: 4px solid #f50057;
        border-left-color: transparent;
        border-bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        -webkit-animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
        animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
    }
    
    .loader-1 .loader-inner {
        position: absolute;
        border: 4px solid #f50057;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        left: calc(50% - 20px);
        top: calc(50% - 20px);
        border-right: 0;
        border-top-color: transparent;
        -webkit-animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
        animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
    }
    /*LOADER-2*/
    
    .loader-2 .loader-star {
        position: static;
        width: 60px;
        height: 60px;
        -webkit-transform: scale(0.7);
            transform: scale(0.7);
        -webkit-animation: loader-2-star 1s ease alternate infinite;
        animation: loader-2-star 1s ease alternate infinite;
    }
    
    .loader-2 .loader-circles {
        width: 8px;
        height: 8px;
        background: #18ffff;
        border-radius: 50%;
        position: absolute;
        left: calc(50% - 4px);
        top: calc(50% - 4px);
        transition: all 1s ease;
        -webkit-animation: loader-2-circles 1s ease-in-out alternate infinite;
        animation: loader-2-circles 1s ease-in-out alternate infinite;
    }
    /*LOADER-3*/
    
    .loader-3 .dot {
        width: 10px;
        height: 10px;
        background: #00e676;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 5px);
    }
    
    .loader-3 .dot1 {
        left: 0px;
        -webkit-animation: dot-jump 0.5s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
        animation: dot-jump 0.5s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
    }
    
    .loader-3 .dot2 {
        left: 20px;
        -webkit-animation: dot-jump 0.5s 0.2s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
        animation: dot-jump 0.5s 0.2s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
    }
    
    .loader-3 .dot3 {
        left: 40px;
        -webkit-animation: dot-jump 0.5s 0.4s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
        animation: dot-jump 0.5s 0.4s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
    }
    /*LOADER-4*/
    
    .loader-4 {
        border: 7px double #ff5722;
        -webkit-animation: ball-turn 1s linear infinite;
        animation: ball-turn 1s linear infinite;
    }
    
    .loader-4:before,
    .loader-4:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: #ff5722;
        border-radius: 50%;
        bottom: 0;
        right: 37px;
    }
    
    .loader-4:after {
        left: 37px;
        top: 0;
    }
    /*LOADER-5*/
    
    .loader-5 {
        border: 8px dotted rgba(255, 255, 0, 1);
        transition: all 1s ease;
        -webkit-animation: dotted-spin 1s linear infinite;
        animation: dotted-spin 1s linear infinite;
        border-bottom-width: 1px;
        border-bottom-color: rgba(255, 255, 0, 0.3);
        border-left-width: 2px;
        border-left-color: rgba(255, 255, 0, 0.5);
        border-top-width: 3px;
        border-right-width: 4px;
        border-top-color: rgba(255, 255, 0, 0.7);
    }
    
    .loader-5 .loader-pacman,
    .loader-pacman {
        position: absolute;
        top: 40px;
        left: 25px;
        width: 0px;
        height: 0px;
        border-right: 12px solid transparent;
        border-top: 12px solid rgba(255, 255, 0, 1);
        border-left: 12px solid rgba(255, 255, 0, 1);
        border-bottom: 12px solid rgba(255, 255, 0, 1);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
    /*LOADER-6*/
    
    .loader-6 {
        border: 6px groove #3d8ddd;
        -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        transition: all 1s ease;
        -webkit-animation: loader-1-inner 1s ease-out alternate infinite;
        animation: loader-1-inner 1s ease-out alternate infinite;
    }
    
    .loader-6 .loader-inner {
        border: 0px inset #a5cef7;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        -webkit-animation: border-zoom 1s ease-out alternate infinite;
        animation: border-zoom 1s ease-out alternate infinite;
    }
    /*LOADER-7*/
    
    .loader-7 .line {
        width: 8px;
        position: absolute;
        border-radius: 5px;
        bottom: 0;
        background: linear-gradient(to bottom, #1ee95d, #5714ce);
    }
    
    .loader-7 .line1 {
        left: 0;
        -webkit-animation: line-grow 0.5s ease alternate infinite;
        animation: line-grow 0.5s ease alternate infinite;
    }
    
    .loader-7 .line2 {
        left: 20px;
        -webkit-animation: line-grow 0.5s 0.2s ease alternate infinite;
        animation: line-grow 0.5s 0.2s ease alternate infinite;
    }
    
    .loader-7 .line3 {
        left: 40px;
        -webkit-animation: line-grow 0.5s 0.4s ease alternate infinite;
        animation: line-grow 0.5s 0.4s ease alternate infinite;
    }
    /*LOADER-8*/
    
    .loader-8 .star1 {
        -webkit-animation: star-jump 0.5s ease-out alternate infinite;
        animation: star-jump 0.5s ease-out alternate infinite;
    }
    
    .loader-8 .star2 {
        -webkit-animation: star-jump 0.5s 0.25s ease-out alternate infinite;
        animation: star-jump 0.5s 0.25s ease-out alternate infinite;
    }
    
    .loader-8 .star3 {
        -webkit-animation: star-jump 0.5s 0.5s ease-out alternate infinite;
        animation: star-jump 0.5s 0.5s ease-out alternate infinite;
    }
    
    .loader-8 .loader-star {
        -webkit-transform: scale3d(0.7, 0.7, 0.7);
        transform: scale3d(0.7, 0.7, 0.7);
    }
    
    .loader-8 .star1 {
        left: 0px;
    }
    
    .loader-8 .star2 {
        left: 25px;
    }
    
    .loader-8 .star3 {
        left: 50px;
    }
    /*LOADER-9*/
    
    .loader-9 .star1 {
        -webkit-animation: stars-pulse 1s ease-in-out infinite;
        animation: stars-pulse 1s ease-in-out infinite;
        left: 0;
    }
    
    .loader-9 .star2 {
        -webkit-animation: stars-pulse 1s 0.2s ease-in-out infinite;
        animation: stars-pulse 1s 0.2s ease-in-out infinite;
        left: 25px;
    }
    
    .loader-9 .star3 {
        -webkit-animation: stars-pulse 1s 0.4s ease-in-out infinite;
        animation: stars-pulse 1s 0.4s ease-in-out infinite;
        left: 50px;
    }
    /*LOADER-10*/
    
    .loader-10 {
        width: auto;
        height: auto;
        -webkit-animation: star-pulse 2s ease-in-out infinite;
        animation: star-pulse 2s ease-in-out infinite;
    }
    
    .loader-10 .loader-star {
        position: static;
    }
    /*LOADER-11*/
    
    .loader-11 {
        /*    animation: stars-rotate 2s cubic-bezier(0, 0, 0.63, 0.77) infinite;*/
        -webkit-animation: stars-rotate 2s linear infinite;
        animation: stars-rotate 2s linear infinite;
    }
    
    .loader-11 .loader-star {
        position: absolute;
    }
    
    .loader-11 .star1 {
        top: 0px;
        left: -7px;
    }
    
    .loader-11 .star2 {
        left: 8px;
        top: -12px;
        position: absolute;
        -webkit-transform: scale(0.8);
            transform: scale(0.8);
        opacity: 0.9;
    }
    
    .loader-11 .star3 {
        left: 26px;
        top: -11px;
        position: absolute;
        -webkit-transform: scale(0.7);
            transform: scale(0.7);
        opacity: 0.8;
    }
    
    .loader-11 .star4 {
        left: 39px;
        top: -2px;
        position: absolute;
        -webkit-transform: scale(0.6);
            transform: scale(0.6);
        opacity: 0.7;
    }
    
    .loader-11 .star5 {
        left: 44px;
        top: 10px;
        position: absolute;
        -webkit-transform: scale(0.5);
            transform: scale(0.5);
        opacity: 0.6;
    }
    
    .loader-11 .star6 {
        left: 45px;
        top: 21px;
        position: absolute;
        -webkit-transform: scale(0.4);
            transform: scale(0.4);
        opacity: 0.5;
    }
    /*LOADER-12*/
    
    .loader-12 {
        -webkit-animation: stars-rotate-reverse 2s ease infinite;
        animation: stars-rotate-reverse 2s ease infinite;
    }
    
    .loader-12 polygon {
        fill: #d500f9 !important;
    }
    
    .loader-12 .loader-star {
        position: absolute;
    }
    
    .loader-12 .star1 {
        top: 0px;
        right: -7px;
    }
    
    .loader-12 .star2 {
        right: 9px;
        top: -12px;
        position: absolute;
        -webkit-transform: scale(0.8);
            transform: scale(0.8);
        -webkit-animation: stars-catch 2s 0.1s ease infinite;
        animation: stars-catch 2s 0.1s ease infinite;
    }
    
    .loader-12 .star3 {
        right: 27px;
        top: -11px;
        position: absolute;
        -webkit-transform: scale(0.7);
            transform: scale(0.7);
        -webkit-animation: stars-catch 2s 0.15s ease infinite;
        animation: stars-catch 2s 0.15s ease infinite;
    }
    
    .loader-12 .star4 {
        right: 41px;
        top: -2px;
        position: absolute;
        -webkit-transform: scale(0.6);
            transform: scale(0.6);
        -webkit-animation: stars-catch 2s 0.2s ease infinite;
        animation: stars-catch 2s 0.2s ease infinite;
    }
    
    .loader-12 .star5 {
        right: 47px;
        top: 10px;
        position: absolute;
        -webkit-transform: scale(0.5);
            transform: scale(0.5);
        -webkit-animation: stars-catch 2s 0.25s ease infinite;
        animation: stars-catch 2s 0.25s ease infinite;
    }
    
    .loader-12 .star6 {
        right: 47px;
        top: 21px;
        position: absolute;
        -webkit-transform: scale(0.4);
            transform: scale(0.4);
        -webkit-animation: stars-catch 2s 0.3s ease infinite;
        animation: stars-catch 2s 0.3s ease infinite;
    }

    /*LOADER-13*/
    
    
    .loader-13 .css-heart {
        position: absolute;
        -webkit-animation: star-fly-out 1s ease alternate infinite;
        animation: star-fly-out 1s ease alternate infinite;
        -webkit-transform: scale(0.2);
                transform: scale(0.2);
    }
    
    .loader-13 .heart1 {
        top: 0;
        left: 30px;
    }
    
    .loader-13 .heart2 {
        left: 60px;
        top: 30px;
    }
    
    .loader-13 .heart3 {
        top: 60px;
        left: 30px;
    }
    
    .loader-13 .heart4 {
        left: 0;
        top: 30px;
    }


    /*LOADER-14*/
    
  
    
    .loader-14 .loader-star {
        position: absolute;
        top: calc(50% - 12px);
        left: calc(50% - 12px);
    }
    
    .star-small {
        -webkit-animation: star-small-pulse 1s ease-in-out alternate infinite;
        animation: star-small-pulse 1s ease-in-out alternate infinite;
    }
    
    .loader-14 .star-big {
        -webkit-animation: star-big-pulse 2s -0.2s ease-in-out infinite;
        animation: star-big-pulse 2s -0.2s ease-in-out infinite;
    }
    /*LOADER-15*/
    
    .loader-15 {
        border: 2px dotted #e11a2b;
        -webkit-animation: stars-rotate-reverse 2s linear both infinite;
        animation: stars-rotate-reverse 2s linear both infinite;
    }
    
    .loader-15 .loader-star {
        -webkit-transform: scale(1.5);
            transform: scale(1.5);
        position: absolute;
        left: calc(50% - 12px);
        top: calc(50% - 13px);
    }
    /*CSS-STAR*/
    
    .css-star {
        margin: 10px 0;
        position: relative;
        display: block;
        width: 0px;
        height: 0px;
        border-right: 26px solid transparent;
        border-bottom: 23px solid #e11a2b;
        border-left: 23px solid transparent;
        -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    }
    
    .css-star:before {
        border-bottom: 18px solid #e11a2b;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        position: absolute;
        height: 0;
        width: 0;
        top: -9px;
        left: -16px;
        display: block;
        content: '';
        /* -webkit-transform: rotate(-35deg);
        -moz-transform: rotate(-35deg);
        -ms-transform: rotate(-35deg);
        -o-transform: rotate(-35deg); */
    }
    
    .css-star:after {
        position: absolute;
        display: block;
        top: 2px;
        left: -26px;
        width: 0px;
        height: 0px;
        border-right: 25px solid transparent;
        border-bottom: 22px solid #e11a2b;
        border-left: 27px solid transparent;
        /* -webkit-transform: rotate(-70deg);
        -moz-transform: rotate(-70deg);
        -ms-transform: rotate(-70deg);
        -o-transform: rotate(-70deg); */
        content: '';
    }
    /*LOADER-16*/
    
    .loader-16 .css-star {
        position: absolute;
        -webkit-transform: rotate(180deg) scale(0.35);
            transform: rotate(180deg) scale(0.35);
    }
    
    .loader-16 .star1 {
        top: -20px;
        left: 5px;
        -webkit-animation: star-flicker 1s 0.1s linear infinite;
        animation: star-flicker 1s 0.1s linear infinite;
    }
    
    .loader-16 .star2 {
        left: 25px;
        top: -10px;
        -webkit-animation: star-flicker 1s 0.25s linear infinite;
        animation: star-flicker 1s 0.25s linear infinite;
    }
    
    .loader-16 .star3 {
        left: 35px;
        top: 10px;
        -webkit-animation: star-flicker 1s 0.5s linear infinite;
        animation: star-flicker 1s 0.5s linear infinite;
    }
    
    .loader-16 .star4 {
        top: 30px;
        left: 27px;
        -webkit-animation: star-flicker 1s 0.6s linear infinite;
        animation: star-flicker 1s 0.6s linear infinite;
    }
    
    .loader-16 .star5 {
        top: 40px;
        left: 5px;
        -webkit-animation: star-flicker 1s 0.7s linear infinite;
        animation: star-flicker 1s 0.7s linear infinite;
    }
    
    .loader-16 .star6 {
        top: 30px;
        left: -15px;
        -webkit-animation: star-flicker 1s 0.8s linear infinite;
        animation: star-flicker 1s 0.8s linear infinite;
    }
    
    .loader-16 .star7 {
        top: 10px;
        left: -25px;
        -webkit-animation: star-flicker 1s 0.9s linear infinite;
        animation: star-flicker 1s 0.9s linear infinite;
    }
    
    .loader-16 .star8 {
        top: -10px;
        left: -15px;
        -webkit-animation: star-flicker 1s 1s linear infinite;
        animation: star-flicker 1s 1s linear infinite;
    }
    /*LOADER-17*/
    
 .loader-17 .css-square { 
      position: absolute;
      top: 50%;
      width: 25px; height: 7px;
    background: white;
    box-shadow: 2px 2px 3px 0px black;
   }

    
    .loader-17 .square1 {
        left: 70px;
        -webkit-animation: dominos 1s 0.125s ease infinite;
        animation: dominos 1s 0.125s ease infinite;
    }
    
    .loader-17 .square2 {
        left: 60px;
        -webkit-animation: dominos 1s 0.3s ease infinite;
        animation: dominos 1s 0.3s ease infinite;
    }
    
    .loader-17 .square3 {
        left: 50px;
        -webkit-animation: dominos 1s 0.425s ease infinite;
        animation: dominos 1s 0.425s ease infinite;
    }
    
    .loader-17 .square4 {
        left: 40px;
        -webkit-animation: dominos 1s 0.540s ease infinite;
        animation: dominos 1s 0.540s ease infinite;
    }
    
    .loader-17 .square5 {
        left: 30px;
        -webkit-animation: dominos 1s 0.665s ease infinite;
        animation: dominos 1s 0.665s ease infinite;
    }
    
    .loader-17 .square6 {
        left: 20px;
        -webkit-animation: dominos 1s 0.79s ease infinite;
        animation: dominos 1s 0.79s ease infinite;
    }
    
    .loader-17 .square7 {
        left: 10px;
        -webkit-animation: dominos 1s 0.9s ease infinite;
        animation: dominos 1s 0.9s ease infinite;
    }
    
    .loader-17 .square8 {
        left: 0px;
        -webkit-animation: dominos 1s 1s ease infinite;
        animation: dominos 1s 1s ease infinite;
    }


    /*LOADER-18*/
    
    .loader-18 .css-star {
        position: absolute;
        -webkit-transform: rotate(180deg) scale(0.5);
            transform: rotate(180deg) scale(0.5);
        opacity: 0.4;
    }
    
    .loader-18 .css-star,
    .loader-18 .css-star:before,
    .loader-18 .css-star:after {
        border-bottom-color: #00e676;
    }
    
    .loader-18 .star1 {
        top: -20px;
        left: 5px;
        -webkit-animation: star-crazyness 1s 0.125s ease infinite;
        animation: star-crazyness 1s 0.125s ease infinite;
    }
    
    .loader-18 .star2 {
        left: 25px;
        top: -10px;
        -webkit-animation: star-crazyness 1s 0.3s ease infinite;
        animation: star-crazyness 1s 0.3s ease infinite;
    }
    
    .loader-18 .star3 {
        left: 35px;
        top: 10px;
        -webkit-animation: star-crazyness 1s 0.425s ease infinite;
        animation: star-crazyness 1s 0.425s ease infinite;
    }
    
    .loader-18 .star4 {
        top: 30px;
        left: 27px;
        -webkit-animation: star-crazyness 1s 0.540s ease infinite;
        animation: star-crazyness 1s 0.540s ease infinite;
    }
    
    .loader-18 .star5 {
        top: 40px;
        left: 5px;
        -webkit-animation: star-crazyness 1s 0.665s ease infinite;
        animation: star-crazyness 1s 0.665s ease infinite;
    }
    
    .loader-18 .star6 {
        top: 30px;
        left: -15px;
        -webkit-animation: star-crazyness 1s 0.79s ease infinite;
        animation: star-crazyness 1s 0.79s ease infinite;
    }
    
    .loader-18 .star7 {
        top: 10px;
        left: -25px;
        -webkit-animation: star-crazyness 1s 0.9s ease infinite;
        animation: star-crazyness 1s 0.9s ease infinite;
    }
    
    .loader-18 .star8 {
        top: -10px;
        left: -15px;
        -webkit-animation: star-crazyness 1s 1s ease infinite;
        animation: star-crazyness 1s 1s ease infinite;
    }
    /*LOADER-19*/
    
    .loader-19 .css-star {
        position: absolute;
        -webkit-transform: rotate(180deg) scale(0.5);
            transform: rotate(180deg) scale(0.5);
    }
    
    .loader-19 .star1 {
        left: 0;
        -webkit-animation: star-crawl 1s ease-out alternate infinite;
        animation: star-crawl 1s ease-out alternate infinite;
    }
    
    .loader-19 .star2 {
        left: 22px;
        -webkit-transform: rotate(180deg) scale(0.45);
            transform: rotate(180deg) scale(0.45);
        -webkit-animation: star-crawl 1s 0.1s ease-out alternate infinite;
        animation: star-crawl 1s 0.1s ease-out alternate infinite;
    }
    
    .loader-19 .star3 {
        left: 44px;
        -webkit-transform: rotate(180deg) scale(0.4);
            transform: rotate(180deg) scale(0.4);
        -webkit-animation: star-crawl 1s 0.2s ease-out alternate infinite;
        animation: star-crawl 1s 0.2s ease-out alternate infinite;
    }
    
    .loader-19 .star4 {
        left: 66px;
        -webkit-transform: rotate(180deg) scale(0.35);
            transform: rotate(180deg) scale(0.35);
        -webkit-animation: star-crawl 1s 0.3s ease-out alternate infinite;
        animation: star-crawl 1s 0.3s ease-out alternate infinite;
    }
    
    .loader-19 .star5 {
        left: 88px;
        -webkit-transform: rotate(180deg) scale(0.3);
            transform: rotate(180deg) scale(0.3);
        -webkit-animation: star-crawl 1s 0.4s ease-out alternate infinite;
        animation: star-crawl 1s 0.4s ease-out alternate infinite;
    }
    
    .loader-19 .star6 {
        left: 110px;
        -webkit-transform: rotate(180deg) scale(0.25);
            transform: rotate(180deg) scale(0.25);
        -webkit-animation: star-crawl 1s 0.5s ease-out alternate infinite;
        animation: star-crawl 1s 0.5s ease-out alternate infinite;
    }
    
    .loader-19 .star7 {
        left: 132px;
        -webkit-transform: rotate(180deg) scale(0.2);
            transform: rotate(180deg) scale(0.2);
        -webkit-animation: star-crawl 1s 0.6s ease-out alternate infinite;
        animation: star-crawl 1s 0.6s ease-out alternate infinite;
    }
    
    .loader-19 .star8 {
        left: 154px;
        -webkit-transform: rotate(180deg) scale(0.15);
            transform: rotate(180deg) scale(0.15);
        -webkit-animation: star-crawl 1s 0.7s ease-out alternate infinite;
        animation: star-crawl 1s 0.7s ease-out alternate infinite;
    }
    
    .loader-20 {
        width: 70px;
        height: 70px;
        border: 3px dashed #d3d3d3;
        -webkit-animation: stars-rotate-reverse 2s linear both infinite;
        animation: stars-rotate-reverse 2s linear both infinite;
    }
    
    .loader-20 .css-diamond {
        position: absolute;
        left: calc(50% - 50px);
        top: calc(50% - 50px);
        -webkit-transform: scale(0.3);
                transform: scale(0.3);
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        border-color: transparent transparent #fff transparent;
    }
    
    .loader-20 .css-diamond:after {
        border-color: lightgrey transparent transparent transparent;
    }
    
    .css-diamond {
        border-style: solid;
        border-color: transparent transparent #ce93d8 transparent;
        border-width: 0 25px 25px 25px;
        height: 0;
        width: 100px;
        position: relative;
        margin: 20px 0 50px 0;
    }
    
    .css-diamond:after {
        content: "";
        position: absolute;
        top: 25px;
        left: -25px;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: #ce93d8 transparent transparent transparent;
        border-width: 70px 50px 0 50px;
    }
    
    .css-heart {
        position: absolute;
  /*      width: 100px;
        height: 90px;*/
    }
    
    .css-heart:before,
    .css-heart:after {
        position: absolute;
        content: "";
        left: 50px;
        top: 0;
        width: 50px;
        height: 80px;
        background: #950d0d;
        border-radius: 50px 50px 0 0;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        -webkit-transform-origin: 0 100%;
                transform-origin: 0 100%;
    }
    
    .css-heart:after {
        left: 0;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        -webkit-transform-origin: 100% 100%;
                transform-origin: 100% 100%;
    }
    
    .css-times {
        position: absolute;
        width: 100px;
        height: 90px;
    }
    
    .css-times:before,
    .css-times:after {
        position: absolute;
        content: "";
        left: 50px;
        top: 0;
        width: 2px;
        height: 65px;
        background: red;
        border-radius: 50px 50px 0 0;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        -webkit-transform-origin: 0 100%;
                transform-origin: 0 100%;
        -webkit-animation: times-background 1s ease-in-out infinite;
                animation: times-background 1s ease-in-out infinite;
    }
    
    .css-times:after {
        left: 0;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        -webkit-transform-origin: 100% 100%;
                transform-origin: 100% 100%;
    }
    
    .loader-21 {
        width: 100px;
    }
    
    .loader-21 .times1 {
        -webkit-animation: times-pulse 1s ease-in-out infinite;
                animation: times-pulse 1s ease-in-out infinite;
        left: 0;
    }
    
    .loader-21 .times2 {
        -webkit-animation: times-pulse 1s 0.2s ease-in-out infinite;
                animation: times-pulse 1s 0.2s ease-in-out infinite;
        left: 25px;
    }
    
    .loader-21 .times3 {
        -webkit-animation: times-pulse 1s 0.4s ease-in-out infinite;
                animation: times-pulse 1s 0.4s ease-in-out infinite;
        left: 50px;
    }
    
    .css-flower {
        position: absolute;
        background: green;
        width: 35px;
        height: 35px;
        position: relative;
        text-align: center;
        -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
        border-radius: 40%;
        border-top: 4px solid #ababa9;
    }
    
    .css-flower:before {
        content: "";
        position: absolute;
        top: -6px;
        left: 0;
        height: 10px;
        width: 20px;
        background: green;
        -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
        border-radius: 10px;
    }
    
    .loader-22 .css-flower {
        position: absolute;
        -webkit-transform: rotate(180deg) scale(0.5);
            transform: rotate(180deg) scale(0.5);
    }
    
    .loader-22 .flower1 {
        left: 0;
        -webkit-animation: caterpillarCrawl 1s ease-out alternate infinite;
                animation: caterpillarCrawl 1s ease-out alternate infinite;
        width: 45px;
        height: 45px;
        top: -10px;
        background: #066c06;
        z-index: 2;
    }
    
    .loader-22 .flower1:before {
        display: none;
    }
    
    .loader-22 .flower2 {
        left: 10px;
        -webkit-transform: rotate(180deg) scale(0.45);
            transform: rotate(180deg) scale(0.45);
        -webkit-animation: caterpillarCrawl 1s 0.1s ease-out alternate infinite;
                animation: caterpillarCrawl 1s 0.1s ease-out alternate infinite;
    }
    
    .loader-22 .flower3 {
        left: 20px;
        -webkit-transform: rotate(180deg) scale(0.4);
            transform: rotate(180deg) scale(0.4);
        -webkit-animation: caterpillarCrawl 1s 0.2s ease-out alternate infinite;
                animation: caterpillarCrawl 1s 0.2s ease-out alternate infinite;
    }
    
    .loader-22 .flower4 {
        left: 30px;
        -webkit-transform: rotate(180deg) scale(0.35);
            transform: rotate(180deg) scale(0.35);
        -webkit-animation: caterpillarCrawl 1s 0.3s ease-out alternate infinite;
                animation: caterpillarCrawl 1s 0.3s ease-out alternate infinite;
    }
    
    .loader-22 .flower5 {
        left: 40px;
        -webkit-transform: rotate(180deg) scale(0.3);
            transform: rotate(180deg) scale(0.3);
        -webkit-animation: caterpillarCrawl 1s 0.4s ease-out alternate infinite;
                animation: caterpillarCrawl 1s 0.4s ease-out alternate infinite;
    }
    
    .loader-22 .flower6 {
        left: 50px;
        -webkit-transform: rotate(180deg) scale(0.25);
            transform: rotate(180deg) scale(0.25);
        -webkit-animation: caterpillarCrawl 1s 0.5s ease-out alternate infinite;
                animation: caterpillarCrawl 1s 0.5s ease-out alternate infinite;
    }

  /* ----------------     KEYFRAMES    ----------------- */
  
  @-webkit-keyframes loader-1-outter {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }
  
  @keyframes loader-1-outter {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }
  
  @-webkit-keyframes loader-1-inner {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(-360deg);
          transform: rotate(-360deg);
      }
  }
  
  @keyframes loader-1-inner {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(-360deg);
          transform: rotate(-360deg);
      }
  }
  
  @-webkit-keyframes loader-2-circles {
      0% {
          box-shadow: 0 0 0 #18ffff;
          opacity: 1;
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      50% {
          box-shadow: 24px -22px #18ffff, 30px -15px 0 -3px #18ffff, 31px 0px #18ffff, 29px 9px 0 -3px #18ffff, 24px 23px #18ffff, 17px 30px 0 -3px #18ffff, 0px 33px #18ffff, -10px 28px 0 -3px #18ffff, -24px 22px #18ffff, -29px 14px 0 -3px #18ffff, -31px -3px #18ffff, -30px -11px 0 -3px #18ffff, -20px -25px #18ffff, -12px -30px 0 -3px #18ffff, 5px -29px #18ffff, 13px -25px 0 -3px #18ffff;
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
      }
      100% {
          opacity: 0;
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
          box-shadow: 25px -22px #18ffff, 15px -22px 0 -3px black, 31px 2px #18ffff, 21px 2px 0 -3px black, 23px 25px #18ffff, 13px 25px 0 -3px black, 0px 33px #18ffff, -10px 33px 0 -3px black, -26px 24px #18ffff, -19px 17px 0 -3px black, -32px 0px #18ffff, -23px 0px 0 -3px black, -25px -23px #18ffff, -16px -23px 0 -3px black, 0px -31px #18ffff, -2px -23px 0 -3px black;
      }
  }
  
  @keyframes loader-2-circles {
      0% {
          box-shadow: 0 0 0 #18ffff;
          opacity: 1;
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      50% {
          box-shadow: 24px -22px #18ffff, 30px -15px 0 -3px #18ffff, 31px 0px #18ffff, 29px 9px 0 -3px #18ffff, 24px 23px #18ffff, 17px 30px 0 -3px #18ffff, 0px 33px #18ffff, -10px 28px 0 -3px #18ffff, -24px 22px #18ffff, -29px 14px 0 -3px #18ffff, -31px -3px #18ffff, -30px -11px 0 -3px #18ffff, -20px -25px #18ffff, -12px -30px 0 -3px #18ffff, 5px -29px #18ffff, 13px -25px 0 -3px #18ffff;
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
      }
      100% {
          opacity: 0;
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
          box-shadow: 25px -22px #18ffff, 15px -22px 0 -3px black, 31px 2px #18ffff, 21px 2px 0 -3px black, 23px 25px #18ffff, 13px 25px 0 -3px black, 0px 33px #18ffff, -10px 33px 0 -3px black, -26px 24px #18ffff, -19px 17px 0 -3px black, -32px 0px #18ffff, -23px 0px 0 -3px black, -25px -23px #18ffff, -16px -23px 0 -3px black, 0px -31px #18ffff, -2px -23px 0 -3px black;
      }
  }
  
  @-webkit-keyframes loader-2-star {
      0% {
          -webkit-transform: scale(0) rotate(0deg);
          transform: scale(0) rotate(0deg);
      }
      100% {
          -webkit-transform: scale(0.7) rotate(360deg);
          transform: scale(0.7) rotate(360deg);
      }
  }
  
  @keyframes loader-2-star {
      0% {
          -webkit-transform: scale(0) rotate(0deg);
          transform: scale(0) rotate(0deg);
      }
      100% {
          -webkit-transform: scale(0.7) rotate(360deg);
          transform: scale(0.7) rotate(360deg);
      }
  }
  
  @-webkit-keyframes dot-jump {
      0% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
      }
      100% {
          -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
      }
  }
  
  @keyframes dot-jump {
      0% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
      }
      100% {
          -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
      }
  }
  
  @-webkit-keyframes ball-turn {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }
  
  @keyframes ball-turn {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }
  
  @-webkit-keyframes dotted-spin {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(-360deg);
          transform: rotate(-360deg);
      }
  }
  
  @keyframes dotted-spin {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(-360deg);
          transform: rotate(-360deg);
      }
  }
  
  @-webkit-keyframes hike {
      0% {
          -webkit-transform: translate(0);
          transform: translate(0);
      }
      25% {
          -webkit-transform: translate(20px, -20px);
          transform: translate(20px, -20px);
      }
      50% {
          -webkit-transform: translate(40px, 0px);
          transform: translate(40px, 0px);
      }
      75% {
          -webkit-transform: translate(60px, -20px);
          transform: translate(60px, -20px);
      }
      100% {
          -webkit-transform: translate(80px, 0px);
          transform: translate(80px, 0px);
      }
  }
  
  @keyframes hike {
      0% {
          -webkit-transform: translate(0);
          transform: translate(0);
      }
      25% {
          -webkit-transform: translate(20px, -20px);
          transform: translate(20px, -20px);
      }
      50% {
          -webkit-transform: translate(40px, 0px);
          transform: translate(40px, 0px);
      }
      75% {
          -webkit-transform: translate(60px, -20px);
          transform: translate(60px, -20px);
      }
      100% {
          -webkit-transform: translate(80px, 0px);
          transform: translate(80px, 0px);
      }
  }
  
  @-webkit-keyframes border-zoom {
      0% {
          border-width: 0px;
      }
      100% {
          border-width: 10px;
      }
  }
  
  @keyframes border-zoom {
      0% {
          border-width: 0px;
      }
      100% {
          border-width: 10px;
      }
  }
  /*@-webkit-keyframes line-grow {
  0% {
  height: 0;
  }
  100% {
  height: 75%;
  }
  }*/
  
  @-webkit-keyframes line-grow {
      0% {
          height: 0;
      }
      100% {
          height: 75%;
      }
  }
  
  @keyframes line-grow {
      0% {
          height: 0;
      }
      100% {
          height: 75%;
      }
  }
  
  @-webkit-keyframes star-jump {
      0% {
          -webkit-transform: translateY(0) scale(0.7);
          transform: translateY(0) scale(0.7);
      }
      100% {
          -webkit-transform: translateY(-15px) scale(1);
          transform: translateY(-15px) scale(1);
      }
  }
  
  @keyframes star-jump {
      0% {
          -webkit-transform: translateY(0) scale(0.7);
          transform: translateY(0) scale(0.7);
      }
      100% {
          -webkit-transform: translateY(-15px) scale(1);
          transform: translateY(-15px) scale(1);
      }
  }
  
  @-webkit-keyframes stars-pulse {
      0%,
      100% {
          -webkit-transform: scale(1);
          transform: scale(1);
          opacity: 1;
      }
      80% {
          -webkit-transform: scale(0);
          transform: scale(0);
          opacity: 0;
      }
  }
  
  @keyframes stars-pulse {
      0%,
      100% {
          -webkit-transform: scale(1);
          transform: scale(1);
          opacity: 1;
      }
      80% {
          -webkit-transform: scale(0);
          transform: scale(0);
          opacity: 0;
      }
  }
  
  @-webkit-keyframes times-pulse {
      0%,
      100% {
          -webkit-transform: scale(1);
          transform: scale(1);
          opacity: 1;
      }
      60% {
          -webkit-transform: scale(0);
          transform: scale(0);
          opacity: 0;
      }
  }
  
  @keyframes times-pulse {
      0%,
      100% {
          -webkit-transform: scale(1);
          transform: scale(1);
          opacity: 1;
      }
      60% {
          -webkit-transform: scale(0);
          transform: scale(0);
          opacity: 0;
      }
  }
  
  @-webkit-keyframes times-background {
      0%,
      100% {
          background: blueviolet;
      }
      60% {
          background: #ff3d00;
      }
  }
  
  @keyframes times-background {
      0%,
      100% {
          background: blueviolet;
      }
      60% {
          background: #ff3d00;
      }
  }
  
  @-webkit-keyframes star-pulse {
      0%,
      100% {
          -webkit-transform: scale(0) rotate(0deg);
          transform: scale(0) rotate(0deg);
          opacity: 0.5;
      }
      25% {
          -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
      }
      50% {
          -webkit-transform: scale(2) rotate(0deg);
          transform: scale(2) rotate(0deg);
          opacity: 1;
      }
      75% {
          -webkit-transform: scale(1.5) rotate(90deg);
          transform: scale(1.5) rotate(90deg);
      }
  }
  
  @keyframes star-pulse {
      0%,
      100% {
          -webkit-transform: scale(0) rotate(0deg);
          transform: scale(0) rotate(0deg);
          opacity: 0.5;
      }
      25% {
          -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
      }
      50% {
          -webkit-transform: scale(2) rotate(0deg);
          transform: scale(2) rotate(0deg);
          opacity: 1;
      }
      75% {
          -webkit-transform: scale(1.5) rotate(90deg);
          transform: scale(1.5) rotate(90deg);
      }
  }
  
  @-webkit-keyframes stars-rotate {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      25% {
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
      }
      50% {
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
      }
      75% {
          -webkit-transform: rotate(-270deg);
          transform: rotate(-270deg);
      }
      100% {
          -webkit-transform: rotate(-360deg);
          transform: rotate(-360deg);
      }
  }
  
  @keyframes stars-rotate {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      25% {
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
      }
      50% {
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
      }
      75% {
          -webkit-transform: rotate(-270deg);
          transform: rotate(-270deg);
      }
      100% {
          -webkit-transform: rotate(-360deg);
          transform: rotate(-360deg);
      }
  }
  
  @-webkit-keyframes stars-rotate-reverse {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }
  
  @keyframes stars-rotate-reverse {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }
  
  @-webkit-keyframes stars-catch {
      0% {}
      25% {}
      50% {}
      75% {
          top: -2px;
          right: -11px;
          opacity: 0;
      }
      100% {}
  }
  
  @keyframes stars-catch {
      0% {}
      25% {}
      50% {}
      75% {
          top: -2px;
          right: -11px;
          opacity: 0;
      }
      100% {}
  }
  
  @-webkit-keyframes star-fly-out {
      0% {
          top: 19px;
          left: 19px;
      }
      100% {}
  }
  
  @keyframes star-fly-out {
      0% {
          top: 19px;
          left: 19px;
      }
      100% {}
  }
  
  @-webkit-keyframes star-cube {
      0%,
      100% {
          -webkit-transform: translate(0px, 0);
          transform: translate(0px, 0);
      }
      25% {
          -webkit-transform: translate(60px);
          transform: translate(60px);
      }
      50% {
          -webkit-transform: translate(60px, 60px);
          transform: translate(60px, 60px);
      }
      75% {
          -webkit-transform: translate(0px, 60px);
          transform: translate(0px, 60px);
      }
  }
  
  @keyframes star-cube {
      0%,
      100% {
          -webkit-transform: translate(0px, 0);
          transform: translate(0px, 0);
      }
      25% {
          -webkit-transform: translate(60px);
          transform: translate(60px);
      }
      50% {
          -webkit-transform: translate(60px, 60px);
          transform: translate(60px, 60px);
      }
      75% {
          -webkit-transform: translate(0px, 60px);
          transform: translate(0px, 60px);
      }
  }
  
  @-webkit-keyframes star-big-pulse {
      0% {
          -webkit-transform: scale(0);
          transform: scale(0);
          opacity: 1;
      }
      100% {
          -webkit-transform: scale(5);
          transform: scale(5);
          opacity: 0;
      }
  }
  
  @keyframes star-big-pulse {
      0% {
          -webkit-transform: scale(0);
          transform: scale(0);
          opacity: 1;
      }
      100% {
          -webkit-transform: scale(5);
          transform: scale(5);
          opacity: 0;
      }
  }
  
  @-webkit-keyframes star-small-pulse {
      0% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
      100% {
          -webkit-transform: scale(2);
          transform: scale(2);
      }
  }
  
  @keyframes star-small-pulse {
      0% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
      100% {
          -webkit-transform: scale(2);
          transform: scale(2);
      }
  }
  
  @-webkit-keyframes star-flicker {
      0% {
          -webkit-transform: rotate(180deg) scale(0.35);
          transform: rotate(180deg) scale(0.35);
      }
      50% {
          -webkit-transform: rotate(180deg) scale(0.1);
          transform: rotate(180deg) scale(0.1);
      }
      100% {
          -webkit-transform: rotate(180deg) scale(0.35);
          transform: rotate(180deg) scale(0.35);
      }
  }
  
  @keyframes star-flicker {
      0% {
          -webkit-transform: rotate(180deg) scale(0.35);
          transform: rotate(180deg) scale(0.35);
      }
      50% {
          -webkit-transform: rotate(180deg) scale(0.1);
          transform: rotate(180deg) scale(0.1);
      }
      100% {
          -webkit-transform: rotate(180deg) scale(0.35);
          transform: rotate(180deg) scale(0.35);
      }
  }
  
  @-webkit-keyframes star-catcher {
      0% {
          opacity: 0.5;
      }
      /*12.5% { opacity: 1;  }*/
      25% {
          opacity: 0.5;
      }
      /*  37.5% {  opacity: 1;  }*/
      50% {
          opacity: 0.5;
      }
      /*  62.5% {opacity: 1;}*/
      75% {
          opacity: 0.5;
      }
      80% {
          opacity: 1;
      }
      100% {
          opacity: 0.5;
      }
  }
  
  @keyframes star-catcher {
      0% {
          opacity: 0.5;
      }
      25% {
          opacity: 0.5;
      }
      50% {
          opacity: 0.5;
      }
      75% {
          opacity: 0.5;
      }
      80% {
          opacity: 1;
      }
      100% {
          opacity: 0.5;
      }
  }
  
  
  @-webkit-keyframes dominos {
  50% { opacity: 0.7; }
  75% { -webkit-transform: rotate(90deg); transform: rotate(90deg); }
  80% { opacity: 1; } 
  }
  
  
  @keyframes dominos {
  50% { opacity: 0.7; }
  75% { -webkit-transform: rotate(90deg); transform: rotate(90deg); }
  80% { opacity: 1; } 
  }
  
  
  @-webkit-keyframes star-crazyness {
      0% {
          opacity: 0.4;
          -webkit-transform: rotate(180deg) translate(0px, 0) scale(0.6);
          transform: rotate(180deg) translate(0px, 0) scale(0.6);
      }
      25% {
          opacity: 0.4;
          -webkit-transform: rotate(180deg) translate(0, 0) scale(0.2);
          transform: rotate(180deg) translate(0, 0) scale(0.2);
      }
      50% {
          opacity: 0.7;
          -webkit-transform: rotate(180deg) translate(5px, 5px) scale(0.4);
          transform: rotate(180deg) translate(5px, 5px) scale(0.4);
      }
      75% {
          opacity: 0.4;
          -webkit-transform: rotate(180deg) translate(0, 0) scale(0.6);
          transform: rotate(180deg) translate(0, 0) scale(0.6);
      }
      80% {
          opacity: 1;
          -webkit-transform: rotate(180deg) translate(5px, 0) scale(0.1);
          transform: rotate(180deg) translate(5px, 0) scale(0.1);
      }
      100% {
          opacity: 0.4;
          -webkit-transform: rotate(180deg) translate(0, 0) scale(0.6);
          transform: rotate(180deg) translate(0, 0) scale(0.6);
      }
  }
  
  @keyframes star-crazyness {
      0% {
          opacity: 0.4;
          -webkit-transform: rotate(180deg) translate(0px, 0) scale(0.6);
          transform: rotate(180deg) translate(0px, 0) scale(0.6);
      }
      25% {
          opacity: 0.4;
          -webkit-transform: rotate(180deg) translate(0, 0) scale(0.2);
          transform: rotate(180deg) translate(0, 0) scale(0.2);
      }
      50% {
          opacity: 0.7;
          -webkit-transform: rotate(180deg) translate(5px, 5px) scale(0.4);
          transform: rotate(180deg) translate(5px, 5px) scale(0.4);
      }
      75% {
          opacity: 0.4;
          -webkit-transform: rotate(180deg) translate(0, 0) scale(0.6);
          transform: rotate(180deg) translate(0, 0) scale(0.6);
      }
      80% {
          opacity: 1;
          -webkit-transform: rotate(180deg) translate(5px, 0) scale(0.1);
          transform: rotate(180deg) translate(5px, 0) scale(0.1);
      }
      100% {
          opacity: 0.4;
          -webkit-transform: rotate(180deg) translate(0, 0) scale(0.6);
          transform: rotate(180deg) translate(0, 0) scale(0.6);
      }
  }
  
  @-webkit-keyframes star-crawl {
      0% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.2);
          transform: rotate(180deg) translateY(0) scale(0.2);
      }
      20% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.3);
          transform: rotate(180deg) translateY(0) scale(0.3);
      }
      40% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.4);
          transform: rotate(180deg) translateY(0) scale(0.4);
      }
      60% {
          -webkit-transform: rotate(90deg) translateY(0) scale(0.4);
          transform: rotate(90deg) translateY(0) scale(0.4);
      }
      80% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.4);
          transform: rotate(180deg) translateY(0) scale(0.4);
      }
      100% {
          -webkit-transform: rotate(180deg) translateY(25px) scale(0.2);
          transform: rotate(180deg) translateY(25px) scale(0.2);
      }
  }
  
  @keyframes star-crawl {
      0% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.2);
          transform: rotate(180deg) translateY(0) scale(0.2);
      }
      20% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.3);
          transform: rotate(180deg) translateY(0) scale(0.3);
      }
      40% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.4);
          transform: rotate(180deg) translateY(0) scale(0.4);
      }
      60% {
          -webkit-transform: rotate(90deg) translateY(0) scale(0.4);
          transform: rotate(90deg) translateY(0) scale(0.4);
      }
      80% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.4);
          transform: rotate(180deg) translateY(0) scale(0.4);
      }
      100% {
          -webkit-transform: rotate(180deg) translateY(25px) scale(0.2);
          transform: rotate(180deg) translateY(25px) scale(0.2);
      }
  }
  
  @-webkit-keyframes caterpillarCrawl {
      0% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.2);
          transform: rotate(180deg) translateY(0) scale(0.3);
      }
      20% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.3);
          transform: rotate(180deg) translateY(0) scale(0.4);
      }
      40% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.4);
          transform: rotate(180deg) translateY(0) scale(0.5);
      }
      60% {
          -webkit-transform: rotate(90deg) translateY(0) scale(0.4);
          transform: rotate(90deg) translateY(0) scale(0.5);
      }
      80% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.4);
          transform: rotate(180deg) translateY(0) scale(0.5);
      }
      100% {
          -webkit-transform: rotate(180deg) translateY(25px) scale(0.2);
          transform: rotate(180deg) translateY(25px) scale(0.3);
      }
  }
  
  @keyframes caterpillarCrawl {
      0% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.2);
          transform: rotate(180deg) translateY(0) scale(0.3);
      }
      20% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.3);
          transform: rotate(180deg) translateY(0) scale(0.4);
      }
      40% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.4);
          transform: rotate(180deg) translateY(0) scale(0.5);
      }
      60% {
          -webkit-transform: rotate(90deg) translateY(0) scale(0.4);
          transform: rotate(90deg) translateY(0) scale(0.5);
      }
      80% {
          -webkit-transform: rotate(180deg) translateY(0) scale(0.4);
          transform: rotate(180deg) translateY(0) scale(0.5);
      }
      100% {
          -webkit-transform: rotate(180deg) translateY(25px) scale(0.2);
          transform: rotate(180deg) translateY(25px) scale(0.3);
      }
  }
  
  /* ---------------------------------------
  End Page Loader
  ---------------------------------------- */
  
.marginClass{
    float: right;
    padding-top: 20%;
}

.textColor{
    color: #007bff;
}

@media only screen and (max-width: 768px) {
   
    .modal-dialog{
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
      top: 0px;
      -webkit-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
    }
    .modelSizeFullScreencontent {
      height: auto !important;
      min-height: 100% !important;
      border-radius: 0 !important;
    }
    
  }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    padding-right: 1.5rem;
}

#layoutAuthentication {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
#layoutAuthentication #layoutAuthentication_content {
    min-width: 0;
    flex-grow: 1;
}
#layoutAuthentication #layoutAuthentication_footer {
    min-width: 0;
}

#layoutSidenav {
    display: flex;
}
#layoutSidenav #layoutSidenav_nav {
    flex-basis: 225px;
    flex-shrink: 0;
    transition: -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    z-index: 1038;
    -webkit-transform: translateX(-225px);
            transform: translateX(-225px);
}
#layoutSidenav #layoutSidenav_content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    flex-grow: 1;
    min-height: calc(100vh - 56px);
    margin-left: -225px;
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
.sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 1037;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
}

@media (min-width: 992px) {
    #layoutSidenav #layoutSidenav_nav {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    #layoutSidenav #layoutSidenav_content {
        margin-left: 0;
        transition: margin 0.15s ease-in-out;
    }

    .sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
        -webkit-transform: translateX(-225px);
                transform: translateX(-225px);
    }
    .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content {
        margin-left: -225px;
    }
    .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
        display: none;
    }
}
.sb-nav-fixed .sb-topnav {
    z-index: 1039;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
    width: 225px;
    height: 100vh;
    z-index: 1038;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
    padding-top: 56px;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu {
    overflow-y: auto;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_content {
    padding-left: 225px;
    top: 56px;
}

#layoutError {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
#layoutError #layoutError_content {
    min-width: 0;
    flex-grow: 1;
}
#layoutError #layoutError_footer {
    min-width: 0;
}

.img-error {
    max-width: 20rem;
}

.nav .nav-link .sb-nav-link-icon,
.sb-sidenav-menu .nav-link .sb-nav-link-icon {
    margin-right: 0.5rem;
}

.sb-topnav {
    padding-left: 0;
    height: 56px;
    z-index: 1039;
}
.sb-topnav .navbar-brand {
    width: 225px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
}
.sb-topnav.navbar-dark #sidebarToggle {
    color: rgba(255, 255, 255, 0.5);
}
.sb-topnav.navbar-light #sidebarToggle {
    color: #212529;
}

.sb-sidenav {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: nowrap;
}
.sb-sidenav .sb-sidenav-menu {
    flex-grow: 1;
}
.sb-sidenav .sb-sidenav-menu .nav {
    flex-direction: column;
    flex-wrap: nowrap;
}
.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-heading {
    padding: 1.75rem 1rem 0.75rem;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link {
    display: flex;
    align-items: center;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    position: relative;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-nav-link-icon {
    font-size: 0.9rem;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-sidenav-collapse-arrow {
    display: inline-block;
    margin-left: auto;
    transition: -webkit-transform 0.15s ease;
    transition: transform 0.15s ease;
    transition: transform 0.15s ease, -webkit-transform 0.15s ease;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link.collapsed .sb-sidenav-collapse-arrow {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-nested {
    margin-left: 1.5rem;
    flex-direction: column;
}
.sb-sidenav .sb-sidenav-footer {
    padding: 0.75rem;
    flex-shrink: 0;
}

.sb-sidenav-dark {
    background-color: #212529;
    color: rgba(255, 255, 255, 0.5);
}
.sb-sidenav-dark .sb-sidenav-menu .sb-sidenav-menu-heading {
    color: rgba(255, 255, 255, 0.25);
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link {
    color: rgba(255, 255, 255, 0.5);
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-nav-link-icon {
    color: rgba(255, 255, 255, 0.25);
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
    color: rgba(255, 255, 255, 0.25);
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link:hover {
    color: #fff;
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link.active {
    color: #fff;
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
    color: #fff;
}
.sb-sidenav-dark .sb-sidenav-footer {
    background-color: #343a40;
}

.sb-sidenav-light {
    background-color: #f8f9fa;
    color: #212529;
}
.sb-sidenav-light .sb-sidenav-menu .sb-sidenav-menu-heading {
    color: #adb5bd;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link {
    color: #212529;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-nav-link-icon {
    color: #adb5bd;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
    color: #adb5bd;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link:hover {
    color: #007bff;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link.active {
    color: #007bff;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
    color: #007bff;
}
.sb-sidenav-light .sb-sidenav-footer {
    background-color: #e9ecef;
}
.app{margin:0 auto;font-size:1.6rem;display:flex;flex-direction:column;justify-content:center;align-items:center;max-width:500px;padding:3rem 1rem 1rem}.app__text{width:50%}.app__collapse{width:100%;display:block;position:relative;overflow:hidden;transition:height 300ms cubic-bezier(0.4, 0, 0.2, 1);background-color:#eeeded}.app__collapse--gradient .app__content{border-color:transparent}.app__collapse--gradient:after{pointer-events:none;transition:opacity 300ms;will-change:opacity;opacity:1;content:"";display:block;position:absolute;top:0;left:0;width:100%;height:100%;background:linear-gradient(transparent 50%, white)}.app__collapse--active:after{opacity:0}.app__content{border:1px solid transparent;padding:1rem 0;margin-bottom:3rem;position:relative;transition:300ms}.app__toggle{width:100%;border:1px solid transparent;padding:1rem;display:flex;justify-items:space-between;align-items:center;border-radius:2px;background-color:#eeeded;transition:background-color 300ms}.app__toggle--active{background-color:#eeeded}.app__toggle-text{font-size:inherit}.app__button{display:block;width:20rem;max-width:100%;margin:0 auto;padding:1rem}.app__image{width:100%;max-width:100%;height:auto;display:block}.app__glyphicon_right{width:100%;max-width:100%;height:auto;display:block;margin-left:0px}.icon{margin:10px;width:1em;height:1em;transition:-webkit-transform 200ms cubic-bezier(0, 1, 0, 1);transition:transform 200ms cubic-bezier(0, 1, 0, 1);transition:transform 200ms cubic-bezier(0, 1, 0, 1), -webkit-transform 200ms cubic-bezier(0, 1, 0, 1);will-change:transform}.icon--expanded{-webkit-transform:rotateZ(90deg);transform:rotateZ(90deg)}.rotate90{-webkit-transform:rotateZ(0deg);transform:rotateZ(0deg)}
@media (min-width: 992px){
    .bellIconMoble{
        display:none !important;
    }
}
@media (min-width: 100px){
    .logoMargin{
        margin-left: 30px;
        margin-top: -28px;
    }
}
    .buttonClass{
        display: inline !important;
    }
.belPadding{
    padding-right: 21px;
}
.logout-btn {
    cursor: pointer;
    margin-left: 10px;
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(111,111,111,0.5); 
    z-index: 9;  
}

.popup-content {
    z-index: 100; 
}


/* Popup box styles */


.popup-content {
    padding: 10px;
    color: #FFFFFF;
    background-color: #FFFFFF;
    width: 575px;
    height: 257px;
    border-radius: 4px;
    margin-top: 18px;
}

@media only screen and (max-width: 768px) {
    .k-animation-container {
        left: 0px !important;
        right: 0px;
    }
    div.popup-content {
      color: #FFFFFF;
      background-color: #FFFFFF;
      width: 100%;
      height:100vh;
      margin-top:10px ;
      margin-bottom: 500px;
      
    }
  }


.menu-grid-item {
    font-size: 26px;
    text-align: left;
    color:#225684;
}

.topic-font{
    font-size: 18px;
    margin-left: 20px;
    font-Weight: bold;
    color:#202124;
}

.description-font{
    font-size: 16px;
    margin-left: 20px;
    color: #8F9094;
}

.notificationDot{
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    top: 1px;
    right: 22px;
    text-align: center;
}

@media screen and (max-width: 480px){
    .notificationDot{
        top: 38px;
        right: 38px;
    }
}



.notificationDot svg{
    display: block;
    width: 100%;
    height: 100%;
}

.belStyle{
    position: relative;
}




.btn_position{
    position: absolute;
    bottom:0 ;
    right: 0;
}


.form-group-right{
    margin-right:6px;
    
  }
  .form-group-left{
    margin-right:20px;
    
  }

  .alert_margin{

    margin: 10px;
  }
  @media only screen and (max-width: 768px) {
   
    .come-from-modal.right .modal-dialog{
      width: 100% !important;
      height: 25px;
      margin: 0 !important;
      padding: 0 !important;
      top: 0px;
      -webkit-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
    }
    .modelSizeFullScreencontent {
      height: auto !important;
      min-height: 100% !important;
      border-radius: 0 !important;
    }
    
  }
.marginClass{
    float: right;
    padding-top: 20%;
}

.textColor{
    color: #007bff;
}
.dotted {
    border: 1px dashed #DCDCDC;
    height: 200px;
    border-color: rgb(189, 185, 189);
    padding: 13px;
}
.containerPadding{
    padding: 8px;;
}
.closeFloat{
   
    float: right;
    margin-top: -35px;;
 
}
.uploadPadding{
    padding-left: 11px;
}
.overflowClass{
    width: 100%;
    height: 160px;
    overflow: auto;
}
.paddingItems{
    padding: 40px;
}
.iconPadding{
    padding-left: 50px;
    float: left;
  }
  .paddingCheckBox{
      padding-right: 55px;
  }
  .createButtonMargin{
    margin: 12px;
  }
  .iconColor{
      color: #225684;
      font-weight:bold;
  }
  .closeIconFloat{
      float: right;
      cursor: pointer;
  }
  .headerColor{
      color: #225684;
    }
 .paddingTop{
    padding-top: 23px;
 }
 .camPadding{
     
    padding-top: 31px;

 }
 .checkbox label:after {
    content: '';
    display: table;
    clear: both;
  }
  
  .checkbox .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.3em;
    height: 1.3em;
    float: left;
    margin-right: .5em;
  }
  
  .checkbox .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 15%;
  }
  
  .checkbox label input[type="checkbox"] {
    display: none;
  }
  
  .checkbox label input[type="checkbox"]+.cr>.cr-icon {
    opacity: 0;
  }
  
  .checkbox label input[type="checkbox"]:checked+.cr>.cr-icon {
    opacity: 1;
  }
  
  .checkbox label input[type="checkbox"]:disabled+.cr {
    opacity: .5;
  }
  @media (max-width: 768px) {
    .buttonMaxwidthGroup{
      width: 100% !important;
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
    .buttonWidth{
      width: 100% !important;
    }
    .imageboxSmallScreenHeight{
      height: 153px !important;
    }
}

#gallery img{height:75vw;object-fit:cover}@media(min-width: 576px){#gallery img{height:35vw}}@media(min-width: 992px){#gallery img{height:18vw}}.carousel-item img{height:60vw;object-fit:cover}@media(min-width: 576px){.carousel-item img{height:350px}}
.fullscreen {
  max-width: 100%;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #F2F2F2;
  z-index: 9999;
}
.fullscreen .tools {
  position: absolute;
  z-index: 99;
  left: 10px;
  top: 10px;
}
.fullscreen .transform-component-module_wrapper__1_Fgj {
  width: 100% !important;
  min-height: 100% !important;;
  height: 100vh !important;;
}
.fullscreen .transform-component-module_content__2jYgh img {
  margin: 0px;
  width: 100%;
}

.iconPadding{
  padding-left: 30px;
  float: right;
}
.buttonPadding{
  padding-left: 30px;
}

    .pb-cmnt-container {
        font-family: Lato;
        margin-top: 100px;
    }

    .pb-cmnt-textarea {
        resize: none;
        padding: 20px;
        height: 130px;
        width: 100%;
        border: 1px solid #F2F2F2;
    }
.shareButtonFloat{
  float: right
}
.commentBoxWidth{
  width: 100%;
}

.no-box-shadow {
  box-shadow: none
}

.no-box-shadow:focus {
  box-shadow: none
}

.day {
  font-size: 9px
}

.heart {
  border: 1px soild green !important;
  border-color: green !important;
  border-radius: 22px
}

.heart-icon {
  color: green
}

.comment-text {
  font-size: 12px
}

.delete {
  font-size: 13px;
  cursor: pointer
}
.popover.bs-tether-element-attached-top::after,
.popover.bs-tether-element-attached-top::before{
    left: 65%;
}
.popover {
  left: -119px !important;
}
.arrow{
  left: 248px !important;
}

.note-popup-dialog{
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
}
/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color:white;
  color: #444;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  background-color: white; 
}

/* Style the accordion content title */
.accordion__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: #eee;
  overflow: auto;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

.hrMargin {
    margin:0;
}
.margin30 {
    margin-bottom: 15px;
}

.item-img-wrap {
    position: relative;
    text-align: center;
    overflow: hidden;
}

.item-img-wrap img {
    transition: all 200ms linear;
    width: 100%;
}

/************************image hover effect*******************/
.item-img-wrap {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.item-img-wrap img {
  transition: all 200ms linear;
  width: 100%;
  height: auto !important;
}

.item-img-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.item-img-overlay span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(http://bootstraplovers.com/templates/assan-2.2/main-template/img/plus.png) no-repeat center center rgba(0, 0, 0, 0.7);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  transition: opacity 250ms linear;
}

.item-img-wrap:hover .item-img-overlay span {
  opacity: 1;
}

.item-img-wrap:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


/*************pagination***********/
.gallery-bottom .pagination {
  margin-top: 0px;
}

.pagination > li > a, .pagination > li > span {
  background-color: #ccc;
  padding: 3px 9px;
  color: #fff;
  border: 0px;
}

.pagination > li > a {
  margin-right: 5px;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #32c5d2;
}

.footerColour{
    background-color: rgba(0, 0, 0, 0.2);
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    float: right;

  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .headerBecground{
      background-color: #e9ecef;
  }
  .dropDownPadding{
    padding-right: 10px;
  }
  #dropdownMenuButton{
      background-color:transparent;
      color:#212529;
      border-color:transparent
  }
  .camTag{
    float: right;
    display: flex;
    
    margin-top: 10px;

}
.BasicexampleMargin{
  margin-top: 10px;
    margin-bottom: 10px;
}
#settingBar{
  width:165%;
}
.settingIconPointer{
  cursor: pointer;
}

.come-from-modal.left .modal-dialog,
.come-from-modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 350px;
    height: 100%;
    top: 112px;
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
    
}
@media (max-width: 767.98px) {
  .come-from-modal.right .modal-dialog{
      width: 100% !important;
      height: 25px;
      margin: 0 !important;
      padding: 0 !important;
      top: 0px;
      -webkit-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
    }
    .modelSizeFullScreencontent {
      height: auto !important;
      min-height: 100% !important;
      border-radius: 0 !important;
    }
  
}

.come-from-modal.left .modal-content,
.come-from-modal.right .modal-content {
    height: 82%;
    overflow-y: auto;
    border-radius: 0px;
}

.come-from-modal.left .modal-body,
.come-from-modal.right .modal-body {
    padding: 15px 15px 80px;
}
.come-from-modal.right.fade .modal-dialog {
    right: -320px;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.come-from-modal.right.fade.in .modal-dialog {
    right: 0;
}
.rightModelTop{
  
}
#myModalLabel{
  /* Filtrer par */
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 18px;
line-height: 22px;
/* identical to box height */
letter-spacing: 0.00132353px;

color: #225684;

}
.hrtopandBottom{
  margin-top: 0rem !important;
    margin-bottom: 0rem !important;
}

/* .modal-backdrop {
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  
  backdrop-filter: blur(5px);
  background-color: #01223770;
} */

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: .5;
}
.modal-open{
  padding-right: 0px !important;
}
.setPadding{
  padding-right: 0px !important;
}

.checkbox label:after {
  content: '';
  display: table;
  clear: both;
}

.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: .25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: .5em;
}

.checkbox .cr .cr-icon {
  position: absolute;
  font-size: .8em;
  line-height: 0;
  top: 50%;
  left: 15%;
}

.checkbox label input[type="checkbox"] {
  display: none;
}

.checkbox label input[type="checkbox"]+.cr>.cr-icon {
  opacity: 0;
}

.checkbox label input[type="checkbox"]:checked+.cr>.cr-icon {
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled+.cr {
  opacity: .5;
}
.pagination{
    float: right;
}
.dataTables_info{
    display: none;
}
#dataTable_length{
    display: none;
}
#dataTable_filter{
    display: none;
}
.fontColorRed{
    color: red;
}
.headerColor{
    color: #225684;
  }
.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    max-width: 60%;
    height:100% ;
    
    margin: 0 auto; /* Added */
          float: none; /* Added */
          margin-top:40px ;
  
  }
  .my-cart {
    max-width: 80%;
    height: auto;
  }
  /* .my-cart .form-item-margin {
    float: left !important;
  } */
  @media only screen and (max-width: 768px) {
    div.card {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;
      max-width: 100%;
      height:100% ;
      
      margin: 0 auto; /* Added */
            float: none; /* Added */
            /* margin-top:40px ; */
    }
  }
  
  .settings_content{
  background-color:#f0f0f0;
  width:100%;
  overflow-y: hidden;
  }
  
  .btn_position{
    position: absolute;
    right: 0;
  
  }
  
  .form-group-right{
    margin-right:6px;
    
  }
  .form-group-left{
    margin-right:20px;
    
  }
  
  .column {
    float: left;
    width: 50%;
    padding: 10px;
    
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
    
  }
  .fragment{
    margin: 6px;
  }
  
  .primaryTop{
  margin-top: 10px;
  margin-right: 0px;
  }
  
  .headerTop{
    margin-top: 40px;
    margin-bottom: 20px;
    
    }
    .form-item-margin{
      margin-top: 20px;
      margin-bottom: 5px;
    }
  
    .gray-lable-margin{
      margin-top: 20px;
      padding-left: 40px;
      padding-bottom: 40px;
    }
    .column_left_alert {
      float: left;
      width: 10%;
      margin-top: 8px;
    }
  
    .column_right_alert {
      float: right;
      width: 90%;
      font-size: 13px;
      margin-top: 8px;
      
    }
  
    .img-center {margin:0 auto;}
  
    .inner-form-item-margin{
      margin-top: 20px;
      
    }
  
    .inner-form-row-margin{
      margin-top: 20px;
      margin-left: 0px;
      margin-right: 0px;
      
    }
    .li {
      width: 33.3%;
      float: left;
    }
    
    img {
      border: 0 none;
      display: inline-block;
      height: auto;
      max-width: 100%;
      vertical-align: middle;
      margin: 8px;
      margin-top: 18px;
   
    }
  
    .img-set-right{
      margin-top: auto;
  
    }
  
  .glyph-icon-posision{
    position: absolute;
    right: 10px;
    padding-right:1rem; 
    
    
    
  }
    
  .column-category {
    margin: 0;
    
  } 
  
  .category-inner-font{
    font-size: 25px;
  }
  
  .collaps_bg{
    background-color: darkkhaki;
  }
    
  .inner-service-row-margin-category{
    margin-top: 20px;
    margin-left: 46px;
    margin-right: 34px;
    margin-bottom: 20px;
    background-color: white;
    
  }
  .inner-service-row-margin-para{
    margin-top: 20px;
    margin-left: 46px;
    margin-right: 34px;
    margin-bottom: 20px;
    background-color: #F0F0F0;
  
    
  }
  .inner-service-button-aligment{
    margin-top: 20px;
    margin-left: 46px;
    margin-right: 34px;
    margin-bottom: 20px;
  }
  
  div.vspace1em {
    clear: both;
    height: 1em;
  }
  
  .popover_style{
    justify-content: 'center';
    padding-left: 10px;
    padding-right: 10px;
   
    background-color: white;
    
  
  }
  
  .poppver_text_style{
    
    justify-content: 'center'
  }
  
  .column_left_tags_photos_drop_down {
    padding-left: 20px;
    float: left;
    width: 35%;
  }
  
  .column_right__tags_photos_drop_down {
    padding-right: 20px;
    padding-left: 10px;
    float: left;
    width: 65%;
    font-size: 12px;
    
  }
  
  .parastyle{
    text-align:justify;
    background: #F0F0F0;
  
  }
  
  
  
  
.app{margin:0 auto;font-size:1.6rem;display:flex;flex-direction:column;justify-content:center;align-items:center;max-width:500px;padding:3rem 1rem 1rem}.app__text{width:50%}.app__collapse{width:100%;display:block;position:relative;overflow:hidden;transition:height 300ms cubic-bezier(0.4, 0, 0.2, 1);background-color:#eeeded}.app__collapse--gradient .app__content{border-color:transparent}.app__collapse--gradient:after{pointer-events:none;transition:opacity 300ms;will-change:opacity;opacity:1;content:"";display:block;position:absolute;top:0;left:0;width:100%;height:100%;background:linear-gradient(transparent 50%, white)}.app__collapse--active:after{opacity:0}.app__content{border:1px solid transparent;padding:1rem 0;margin-bottom:3rem;position:relative;transition:300ms}.app__toggle{width:100%;border:1px solid transparent;padding:1rem;display:flex;justify-items:space-between;align-items:center;border-radius:2px;background-color:#eeeded;transition:background-color 300ms}.app__toggle--active{background-color:#eeeded}.app__toggle-text{font-size:inherit}.app__button{display:block;width:20rem;max-width:100%;margin:0 auto;padding:1rem}.app__image{width:100%;max-width:100%;height:auto;display:block}.app__glyphicon_right{width:100%;max-width:100%;height:auto;display:block;margin-left:0px}.icon{margin:10px;width:1em;height:1em;transition:-webkit-transform 200ms cubic-bezier(0, 1, 0, 1);transition:transform 200ms cubic-bezier(0, 1, 0, 1);transition:transform 200ms cubic-bezier(0, 1, 0, 1), -webkit-transform 200ms cubic-bezier(0, 1, 0, 1);will-change:transform}.icon--expanded{-webkit-transform:rotateZ(90deg);transform:rotateZ(90deg)}.rotate90{-webkit-transform:rotateZ(0deg);transform:rotateZ(0deg)}
.producteure-dashboard-grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 26px;
    text-align: center;
    max-width: 416px; 
    height: 220px;
    margin: 17px auto;
    color:#225684;
}

.producteure-dashboard-table-box{
    background-color: rgba(255, 255, 255, 0.8);
    margin: auto;
    max-width: 849px;
    padding: 10px;
    
    
}

.producteure-dashboard-box{
    margin: auto;
    max-width: 849px;   
    padding: 10px;
    
}

.producteure-dashboard-button{
    background: #0178D4;
}

@media only screen and (max-width: 768px){
    .mobilehidden{
          display : none;
    }
    .producteure-dashboard-grid-item{
        background-color: rgba(255, 255, 255, 0.8);
        font-size: 16px;
        text-align: center;
        max-width: 416px; 
        height: 220px;
        margin: 17px auto;
        color:#225684;
    }
}

@media only screen and (min-width: 768px){
    .desktophidden{
          display : none;
    }
}


.consultant-notification-pagination {
    display: inline-block;
    
  }
  
  .consultant-notification-pagination a {
    color: black;
    float: left;
    padding: 8px 11px;
    text-decoration: none;
  }
  
  .consultant-notification-pagination a.active {
   
    color:#FFFFFF;
    width:20px;
    height: 22px;
    margin-right:200px;
  }
  
  .consultant-notification-pagination a:hover:not(.active) {background-color:#225684;color:#FFFFFF;}

  .consultant-notification-mobile-btn {
    background-color: #225684;
    border: none;
    color: white;
    font-size: 14px;
    cursor: pointer;
    width: 55px;
    height: auto;
    
  }

  .consultant-notification-mobile-arrow-color-btn{
    color: black;
  }

  .consultant-notification-mobile-btn-aligment{
    margin: auto;
    width: 50%;
    padding: 10px
  }

  .consultant-notification-bottom-button {
   
    left: 50%;

  }
  
  

.producteure-note-main-box{
    background-color: rgba(255, 255, 255, 0.8);
    margin: auto;
    max-width: 1200px;
    padding: 10px;
    
}


.producteure-note-hr{
    margin-left: auto;
    margin-right: auto;
}

.producteure-note-button2{
    float: right;
    width: 122px;
    height: 30px;
    color: #0178D4;
    background-color: #FFFFFF;
    border: 1px solid #0178D4;
    box-sizing: border-box;
    border-radius: 4px;
}

.producteure-note-dropdownnumber{
    color: #8F9094;  
}

.hrshadow{
    border:none;
  height: 20px;
	height: 50px;
	margin-top: 0;
	box-shadow: 0 13px 13px -13px #333;
    margin-left: auto;
    margin-right: auto;
}



@media only screen and (max-width: 768px){
  .producteure-note-sub-box{
    background-color: #F0F0F0;    
    margin: auto;
    max-width: 1100px;
   
   }

  .producteure-note-button{
    float: left;
    margin-top: 20px;
    width: 157px;
    height: 40px;
    border: 1px solid #0178D4;
    background-color: #0178D4;
    color: #FFFFFF;
    border-radius: 4px;
  }

  .producteure-note-button-aligment-mobile{
    margin-top:20px;
  }
}

@media only screen and (min-width: 768px){
  .producteure-note-sub-box{
    background-color: #F0F0F0;    
    margin: auto;
    max-width: 1100px;
    padding: 10px;
    
  }
  .producteure-note-button{
    float: right;
    margin-top: 20px;
    width: 157px;
    height: 40px;
    border: 1px solid #0178D4;
    background-color: #0178D4;
    color: #FFFFFF;
    border-radius: 4px;
  }

}

.producteure-note-icon-navigation{
    float: right;
    margin-bottom: 20;
}

.producteure-note-sub-box-2{
    background-color: #FFFFFF;    ;
    margin: auto;
    max-width: auto;
    padding: auto;

    
}

.producteure-note-button3{
    float: right;
    width: 122px;
    height: 30px;
    color: #0178D4;
    background-color: #FFFFFF;
    border: 1px solid #0178D4;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 10px;
}

.producteure-note-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* width: 321.33px;
    height: 241px; */
}



.producteure-note-pagination {
    display: inline-block;
    
  }
  
  .producteure-note-pagination a {
    color: black;
    float: left;
    padding: 8px 11px;
    text-decoration: none;
  }
  
  .producteure-note-pagination a.active {
   
    color:#FFFFFF;
    width:20px;
    height: 22px;
    margin-right:200px;
  }
  
  .producteure-note-pagination a:hover:not(.active) {background-color:#225684;color:#FFFFFF;}

  .producteure-note-mobile-btn {
    background-color: #225684;
    border: none;
    color: white;
    font-size: 14px;
    cursor: pointer;
    width: 55px;
    height: auto;
    
  }

  .producteure-note-mobile-arrow-color-btn{
    color: black;
  }

  .producteure-note-mobile-btn-aligment{
    margin: auto;
    width: 50%;
    padding: 10px
  }

  .producteure-note-bottom-button{
    /* position: absolute;
    left: 9.2%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */
    width: auto;
    height: 40px;
    border: 1px solid #0178D4;
    color: #0178D4;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .producteure-note-sidebar{
    background-color:  #FFFFFF;
    
  }

  .producteure-note-doted-button-aligment{
    margin-top: 0px;
    margin-left: 40px;
    cursor: pointer;
  }

  .notesidebar-firstcoloum-background-colour{
    background-color:  #F1F3F9;
    
  }

  .producture-note-topic-fonts{
    color: #202124;
    /* font-size: 16px; */
    font-weight: bold;
  }

  .producture-note-paragraph{
    color: #202124;
  }
  
  .producture-note-modal-button{
    width: 282px;
    height: 71px;
    color: #202124;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    /* border-radius: 4px;
    margin-top: 10px; */
  }
  
  .producture-note-mobile-filter-button{
    margin-right: 130px;
  }
  

  .producture-note-mobile-filter-Touteffacer-button{
    float: right;
    width: 122px;
    height: 40px;
    color: #0178D4;
    background-color: #FFFFFF;
    border: 1px solid #0178D4;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 60px;
  }

  .producture-note-mobile-filter-appliqure-button{
    width: 122px;
    height: 40px;
    margin-right: 40px;
  }

  .producture-note-mobile-Supprimerlapage-button{
    margin-left: 120px;
  }

  .producture-note-mobile-pagination{
    background-color: #225684;
    ;
  }

  .producture-note-mobile-pagination-arrow-ailgment{
    margin-left: 20px;
  }

  .producture-note-filter-mobile-color{
    color: black;
  }

  .producture-note-filterpar-mobile-color{
    color: #225684;
    ;
  }

  .chip, .singleChip {
    white-space: normal !important;
}
/* If any modal popup issues, please remove this below comment */
.modal-backdrop.fade {
  opacity: 0;
  display: none !important;
} 
.modal::after {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}
.modal-dialog {
  position: relative;
  width: 700px;
  z-index: 1044;
}
.app__collapse .parastyle {
  cursor: pointer;
}
.bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

/* producture note side bar*/


@media only screen and (max-width: 768px){
  .producture-note-margin{
    margin: 0px;
  }
}

@media only screen and (min-width: 768px){
  .producture-note-margin{
    margin: 20px;
  }
}
.producture-note-mini-icon-aligmment{
  margin-top: 10px;
  margin-left: -20px;
}

.producture-note-mobile-sidebar-font-color{
  color: black;
}

.producture-note-sidebar-arrow{
  margin-top: 5px;
}

.producture-note-mobile-sidebar-background-color{
  background-color: white;
}
.producteure-note-popup-button2{
    float: left;
    width: 122px;
    height: 30px;
    color: #0178D4;
    background-color: #FFFFFF;
    border: 1px solid #0178D4;
    box-sizing: border-box;
    border-radius: 4px;
}

.producteure-note-popup-font-colour{
    color:#202124;

}
.producture-note-mobile-popup-wordaligment{
    margin-left: 250px;
}
.producture-note-mobile-popup-numberaligment{
    margin-left: 270px;
}

.producture-note-mobile-popup-numberaligment2{
    margin-left: 259px;
}

.producture-note-mobile-popup-numbercolour{
    color:#8F9094;

}

.producture-note-mobile-popup-backgroundcolour{
    background-color: white;
}

.producture-note-mobile-popup-wordaligment2{
    margin-left: 20px
}



.producture-note-mobile-popup-wordaligment4{
    margin-left: 290px;
}

.producture-note-mobile-popup-wordaligment5{
    margin-left: 260px;
}
.producture-note-mobile-popup-wordaligment{
    margin-left: 250px;
}
.producture-note-mobile-popup-numberaligment{
    margin-left: 270px;
}

.producture-note-mobile-popup-numberaligment2{
    margin-left: 259px;
}

.producture-note-mobile-popup-numbercolour{
    color:#8F9094;

}

.producture-note-mobile-popup-backgroundcolour{
    background-color: white;
}

.producture-note-mobile-popup-wordaligment2{
    margin-left: 20px
}



.producture-note-mobile-popup-wordaligment4{
    margin-left: 290px;
}

.producture-note-mobile-popup-wordaligment5{
    margin-left: 260px;
}
.producteure-note-popup-button2{
    float: left;
    width: 122px;
    height: 30px;
    color: #0178D4;
    background-color: #FFFFFF;
    border: 1px solid #0178D4;
    box-sizing: border-box;
    border-radius: 4px;
}

.producteure-note-popup-font-colour{
    color:#202124;

}


  input[type=checkbox] {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
}
.btn-margin{margin-top:2.8rem !important}.text-alignment{float:left !important}.align-center{align-self:center}.btn-margin-btm{margin-bottom:2.8rem !important}@media only screen and (max-width: 575px){.btn-margin{margin-top:.8rem !important}.row-margin{margin-top:.8rem !important}}@media(min-width: 1201px)and (max-width: 1260px){.btn-margin{margin-top:4.3rem !important}}
.headerTop{margin-top:30px}.input-picker{max-width:200px}tr{border-bottom:1px #f0f0f0}th{padding-left:30px}td{padding-left:30px}.ul-padding{margin-bottom:7px}.action-padding{padding-left:60px}@media only screen and (max-width: 765px){.ul-padding{padding-left:8px;margin-bottom:8px}tr{padding-top:20px}th{padding-top:10px}td{align-self:center !important}img{margin-top:8px}#enterpriseTable table,#enterpriseTable thead,#enterpriseTable tbody,#enterpriseTable th,#enterpriseTable td,#enterpriseTable tr{display:block}#enterpriseTable thead tr{position:absolute;top:-9999px;left:-9999px}#enterpriseTable td{border:none;position:relative;padding-left:70%;white-space:normal;text-align:left;align-self:center}#enterpriseTable td:before{position:absolute;top:10px;left:6px;width:45%;padding-right:10px;white-space:nowrap;text-align:left;font-weight:bold;align-self:center}#enterpriseTable td:before{content:attr(data-title)}}
.headerTop{margin-top:30px}.input-picker{max-width:200px}tr{border-bottom:1px #f0f0f0}th{padding-left:30px}td{padding-left:30px}.ul-padding{margin-bottom:7px}.action-padding{padding-left:60px}@media only screen and (max-width: 765px){.ul-padding{padding-left:8px;margin-bottom:8px}tr{padding-top:20px}th{padding-top:10px}td{align-self:center !important}img{margin-top:8px}#enterpriseTable table,#enterpriseTable thead,#enterpriseTable tbody,#enterpriseTable th,#enterpriseTable td,#enterpriseTable tr{display:block}#enterpriseTable thead tr{position:absolute;top:-9999px;left:-9999px}#enterpriseTable td{border:none;position:relative;padding-left:70%;white-space:normal;text-align:left;align-self:center}#enterpriseTable td:before{position:absolute;top:10px;left:6px;width:45%;padding-right:10px;white-space:nowrap;text-align:left;font-weight:bold;align-self:center}#enterpriseTable td:before{content:attr(data-title)}}
