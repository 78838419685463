.producture-note-mobile-popup-wordaligment{
    margin-left: 250px;
}
.producture-note-mobile-popup-numberaligment{
    margin-left: 270px;
}

.producture-note-mobile-popup-numberaligment2{
    margin-left: 259px;
}

.producture-note-mobile-popup-numbercolour{
    color:#8F9094;

}

.producture-note-mobile-popup-backgroundcolour{
    background-color: white;
}

.producture-note-mobile-popup-wordaligment2{
    margin-left: 20px
}



.producture-note-mobile-popup-wordaligment4{
    margin-left: 290px;
}

.producture-note-mobile-popup-wordaligment5{
    margin-left: 260px;
}