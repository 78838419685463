.marginClass{
    float: right;
    padding-top: 20%;
}

.textColor{
    color: #007bff;
}

@media only screen and (max-width: 768px) {
   
    .modal-dialog{
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
      top: 0px;
      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
    }
    .modelSizeFullScreencontent {
      height: auto !important;
      min-height: 100% !important;
      border-radius: 0 !important;
    }
    
  }